import React, { useContext, useEffect } from "react";
import { LoginContext } from "../Contexts/LoginContext";
import { Navigate, Outlet } from "react-router-dom";
import { URL_PATH } from "../Utils/Constants/constants";
import { getSessionIdToken } from "../Utils/Auth/session";

const ProtectedRoutes = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);

  useEffect(() => {
    const { token, id } = getSessionIdToken();
    if (!token || !id) {
      setIsLoggedIn(false);
    }
  }, [setIsLoggedIn]);

  if (isLoggedIn) {
    return <Outlet />;
  } else {
    return <Navigate to={URL_PATH.login} />;
  }
};

export default ProtectedRoutes;
