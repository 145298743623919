import React from 'react'
import ImageSubstitute from '../ImageSubstitute'

const MatrimonialProfileCard = ({
    imgUrl,
    id,
    age,
    name,
    gender,
    location,
    viewMore
}) => {
    return (
        <div className="col-md-3 col-sm-6 mb-3">
            <div className="card text-center border-none team-item-1">
                <div className="lab-inner">
                    <div className="lab-thumb mx-auto" style={{
                        height: '180px',
                        width: '180px'
                    }}>
                        {
                            imgUrl && imgUrl !== `${process.env.REACT_APP_API_URL}/` ? <img
                                src={imgUrl}
                                className="card-img-top"
                                alt="product"
                            /> :
                                <div className='h-100 w-100 rounded-circle overflow-hidden card-img-top'>
                                    <ImageSubstitute name={name} />
                                </div>
                        }
                    </div>
                    <div className="lab-content">
                        <h6 className="card-title mb-0">{name} </h6>
                        <ul className="list-unstyled">
                            <li>
                                <strong>Age:</strong> <span>{age}</span>
                            </li>
                            <li>
                                <strong>Gender:</strong> <span>{gender}</span>
                            </li>
                            <li>
                                <strong>Loaction:</strong> <span>{location}</span>
                            </li>
                        </ul>
                        <div className="social-share">
                            <button className='btn'
                                style={{
                                    outline: 'none !important',
                                    boxShadow: 'none'
                                }}
                                onClick={viewMore}>
                                View Profile
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MatrimonialProfileCard