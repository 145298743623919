import React from "react";

const HeaderFooterErrorBar = ({ fetchHeader, error, isOnLine }) => {
  if (error) {
    return (
      <div
        className={`d-flex justify-content-between align-items-center flex-wrap bg-gradient bg-opacity-10 ${isOnLine ? 'bg-secondary' : 'bg-danger'}`}
        style={{
          fontSize: "0.8rem",
        }}
      >
        <p className="p-0 pl-2 m-0 text-white">{isOnLine ? error : "No internet connection"}</p>
        <button
          className={`btn ${isOnLine ? '' : ''}`}
          style={{
            fontSize: "0.8rem",
          }}
          onClick={() => {
            fetchHeader();
          }}
        >
          Refresh
        </button>
      </div>
    );
  }
  return <></>;
};

export default HeaderFooterErrorBar;
