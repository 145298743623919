import React, { useContext, useEffect, useState } from 'react'
import { API_PATHS, errorMessages, RESULTS_PER_PAGE, URL_PATH } from '../../Utils/Constants/constants';
import { MemberDetails } from '../../Contexts/MemberDetailContext';
import { getApiResponse } from '../../Utils/AxiosRequest';
import Modal from '../../Components/Modal';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import ConditionalLoader from '../../Components/ConditionalLoader';
import deleteIcon from '../../Assets/icons/delete.png';
import editIcon from '../../Assets/icons/edit.png';
import galleryIcon from '../../Assets/icons/gallery.png';
import viewIcon from '../../Assets/icons/view.png';
import prefenceIcon from '../../Assets/icons/feedback.png';
import EditPartnerPreference from '../../Components/EditPartnerPreference';
import EditGallery from '../../Components/EditGallery';
import ConfirmationPopup from '../../Components/ConfirmationPopup';
import SessionTimoutPopup from '../../Components/SessionTimoutPopup';
import CustomPagination from '../../Components/CustomPagination';
import CustomButton from '../../Components/CustomButton';
import CenterModal from '../../Components/CenterModal';


const MatrimonialProfiles = () => {
    const { isLoading, memberDetails, matProfileCount, setMatProfileCount } =
        useContext(MemberDetails);
    const navigate = useNavigate();
    const [isLoadingProfile, setIsLoading] = useState(true);
    const [sessionTimeout, setSessionTimeout] = useState(false);
    const [currentPage, setcurrentPage] = useState(0);
    const [members, setMembers] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [loadError, setLoadError] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [message, setMessage] = useState({
        heading: "",
        description: "",
    })
    const [sortOrder, setSortOrder] = useState(0);
    const [showPreferencePopup, setShowPreferencePopup] = useState({});
    const [showGallaryPopup, setShowGallaryPopup] = useState({});
    const [showConfirmDelete, setShowConfirmDelete] = useState('');
    const { updateToastMessage } = useOutletContext();

    const memberId = memberDetails.MemberId;
    const sortedMembers = members.sort((a, b) => {
        if (sortOrder === "0")
            return 0
        else if (sortOrder === "1")
            return (a.FirstName + (a.LastName ?? '')).localeCompare((b.FirstName + (b.LastName ?? '')))
        else
            return (b.FirstName + (b.LastName ?? '')).localeCompare((a.FirstName + (a.LastName ?? '')));
    })
    const totalPages = Math.ceil(members.length / RESULTS_PER_PAGE);


    const closePopup = () => {
        setShowModal(false);
        setMessage({
            heading: "",
            description: "",
        });
    };

    const handleSOrtChange = (e) => {
        setSortOrder(e.target.value);
    };

    const handleView = (e, id) => {
        e.preventDefault();
        navigate(URL_PATH.matrimonialprofiles + `/${id}`);
    }
    const handleEdit = (e, id) => {
        e.preventDefault();
        navigate(URL_PATH.editMatrimonialProfile + `/${id}`);
    }
    const handleEditPreference = (e, item) => {
        e.preventDefault();
        setShowPreferencePopup({
            show: true,
            id: item.MemberMatrimonial_Id,
            disabled: item.IsApproved !== null
        });
    }

    const handleEditGallery = (e, item) => {
        e.preventDefault();
        setShowGallaryPopup({
            show: true,
            id: item.MemberMatrimonial_Id,
            disabled: item.IsApproved !== null
        });

    }
    const handleDeleteProfileClick = (e, id) => {
        e.preventDefault();
        setShowConfirmDelete(id);
    }

    const confirmDelete = async () => {
        setIsDeleting(true)
        const response = await getApiResponse(API_PATHS.deleteMatmonialProfile + `?mmid=${showConfirmDelete}`, true);
        setShowConfirmDelete('');
        if (response.status === 200) {
            updateToastMessage("Profile deleted successfully.", "success");
            setMatProfileCount(matProfileCount - 1);
        } else if (response.status === 401) {
            updateToastMessage(errorMessages.sessionTimeout, "danger");
        } else {
            updateToastMessage(errorMessages.sessionTimeout, "danger");
        }
        setIsDeleting(false);
    }
    const cancelDelete = () => {
        setShowConfirmDelete('');
    }

    const closeEditPopup = () => {
        setShowGallaryPopup(prvs => (
            { ...prvs, show: false }
        ));
        setShowPreferencePopup(prvs => (
            { ...prvs, show: false }
        ));
        setShowConfirmDelete(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            if (memberId) {
                const response = await getApiResponse(
                    API_PATHS.matrimonialProfiles + `?memberid=${memberId}`,
                    true
                );
                if (response.status === 200) {
                    setMembers(response.data);
                    setcurrentPage(0);
                } else if (response.status === 401) {
                    setSessionTimeout(true);
                } else {
                    setLoadError(errorMessages.error)
                }
            }
            setIsLoading(false);
        };
        if (!isDeleting) {
            fetchData();
        }
    }, [memberId, isDeleting])

    return (
        <SessionTimoutPopup isSessionTimout={sessionTimeout}>
            <div className="container mt-3">
                <div className="update-profile-body">
                    <div>
                        <form className="common-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <select className="custom-select" defaultValue={"0"} onChange={handleSOrtChange}>
                                            <option value="0">Sort</option>
                                            <option value="1">Sort by A-Z</option>
                                            <option value="-1">Sort by Z-A</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-6 d-flex justify-content-end' style={{ height: 'fit-content' }}>
                                    <Link className={`'btn green-btn bg-green' ${members.length >= 5 ? 'disabled' : ''}`} to={URL_PATH.createprofile}>Create Profile</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="table-responsive custome-data-table">
                        <ConditionalLoader isLoading={isLoading || isLoadingProfile} error={loadError}>
                            <div style={{ minHeight: "500px" }}>
                                {sortedMembers.length ? <table
                                    className="table"
                                >
                                    <thead>
                                        <tr>
                                            <th style={{
                                                textWrap: "nowrap"
                                            }}>S.No.</th>
                                            <th style={{
                                                textWrap: "nowrap"
                                            }}>Name</th>
                                            <th style={{
                                                textWrap: "nowrap"
                                            }}>Relation</th>
                                            <th style={{
                                                textWrap: "nowrap"
                                            }}>Mobile Number</th>
                                            <th style={{
                                                textWrap: "nowrap"
                                            }}>Status</th>
                                            <th style={{
                                                textWrap: "nowrap"
                                            }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedMembers.length &&
                                            sortedMembers
                                                .slice(
                                                    (currentPage) * RESULTS_PER_PAGE,
                                                    (currentPage + 1) * RESULTS_PER_PAGE
                                                )
                                                .map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{`${item?.FirstName} ${item?.LastName}`}</td>
                                                        <td>{item?.ProfileCatName}</td>
                                                        <td>{item?.PhoneNumber} </td>
                                                        <td>{item.IsApproved === null ?
                                                            <div>
                                                                <span>Approval Pending</span><div style={{ display: 'inline-block', width: '10px', height: '10px' }} className="bg-primary rounded-circle ml-2"></div>
                                                            </div>
                                                            : item.IsApproved ?
                                                                <div>
                                                                    <span>Approved</span><div style={{ display: 'inline-block', width: '10px', height: '10px' }} className="bg-success rounded-circle ml-2"></div>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <span>Rejected</span><div style={{ display: 'inline-block', width: '10px', height: '10px' }} className="bg-danger rounded-circle ml-2"></div>
                                                                </div>
                                                        }
                                                        </td>
                                                        <td>
                                                            <div className='d-flex justify-content-start gap-2 align-items-start'>
                                                                <CustomButton className='btn btn-sm' onClick={(e) => {
                                                                    if (item.IsApproved === null) {
                                                                        handleEdit(e, item.MemberMatrimonial_Id)
                                                                    }
                                                                }} disabled={item.IsApproved !== null} label={item.IsApproved ? "Can't Edit Profile" : "Edit Profile"}>
                                                                    <img height={24} width={24} src={editIcon} alt="edit" />
                                                                </CustomButton>
                                                                <CustomButton className='btn btn-sm' onClick={(e) => handleView(e, item.MemberMatrimonial_Id)} label="View Profile">
                                                                    <img height={24} width={24} src={viewIcon} alt="view" />
                                                                </CustomButton>
                                                                <CustomButton className='btn btn-sm' onClick={(e) => {
                                                                    handleEditPreference(e, item)
                                                                }}
                                                                    label={item.IsApproved === null ? "Edit Preference" : "View Preference"}>
                                                                    <img height={28} width={28} src={prefenceIcon} alt="preference" />
                                                                </CustomButton>
                                                                <CustomButton className='btn btn-sm' onClick={(e) => {
                                                                    handleEditGallery(e, item)
                                                                }
                                                                }
                                                                    label={item.IsApproved === null ? "Edit Gallery" : "View Gallery"}>
                                                                    <img height={24} width={24} src={galleryIcon} alt="gallery" />
                                                                </CustomButton>
                                                                <CustomButton className='btn btn-sm' onClick={(e) => handleDeleteProfileClick(e, item.MemberMatrimonial_Id)} label="Delete Profile">
                                                                    <img height={24} width={24} src={deleteIcon} alt="delete" />
                                                                </CustomButton>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                    </tbody>
                                </table> : <p className='p-4 text-center h4'>No Profile found <Link to={URL_PATH.createprofile}>Create One</Link></p>}
                            </div>
                            {sortedMembers.length ? (
                                <div className="d-flex justify-content-center pagination">
                                    {totalPages && (
                                        <CustomPagination pageNumber={currentPage} setpageNumber={setcurrentPage} numberOfPages={totalPages} />

                                    )}
                                </div>
                            ) : <></>}
                        </ConditionalLoader>
                    </div>
                </div>
                <CenterModal
                    show={showModal}
                    onClose={closePopup}
                    heading={message.heading}
                    description={message.description}
                />
                <EditPartnerPreference profileId={showPreferencePopup.id} showModal={showPreferencePopup.show} disabled={showPreferencePopup.disabled} heading={"Edit Preference"} closeModal={closeEditPopup} />
                <EditGallery profileId={showGallaryPopup.id} showModal={showGallaryPopup.show} disabled={showGallaryPopup.disabled} closeModal={closeEditPopup} />
                <ConfirmationPopup showModal={showConfirmDelete} closeModal={cancelDelete}
                    confirmCallback={confirmDelete}
                    denyCallback={cancelDelete}
                    heading={"Confirm delete Profile"} />
            </div>
        </SessionTimoutPopup>
    );
}

export default MatrimonialProfiles