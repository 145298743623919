import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const CustomButton = ({
    icon,
    label,
    height,
    width,
    onClick,
    disabled,
    children
}) => {
    const [isVisible, setIsVisible] = useState(false)
    return (
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top"
                style={{
                    color: 'white',
                    fontSize: '1rem',
                }}>{label}</Tooltip>}
        >
            <button
                style={{
                    outline: 'none !important',
                    boxShadow: 'none',
                    cursor: disabled ? "not-allowed" : "pointer"
                }}
                className='btn btn-sm hover:border-2 hover:border-primary' disabled={disabled} onClick={onClick}>
                {icon && <img height={height ?? 24} width={width ?? 24} src={icon} alt="edit" />}
                {children ?? ''}
            </button>
        </OverlayTrigger>
    )
}

export default CustomButton