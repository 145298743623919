import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { postData } from "../Utils/AxiosRequest";
import { API_PATHS, URL_PATH } from "../Utils/Constants/constants";

import CenterModal from "../Components/CenterModal";

const Payment = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const closeModal = () => {
    setShowModal(false);
    setIsSubmitting(false);
    navigate(URL_PATH.login);
  };

  const handlePayment = async () => {
    setIsSubmitting(true);
    const requestBody = {
      PaymentId: state.MemberId,
      MemberId: state.MemberId,
      TransactionId: state.MemberId,
      PaymentAmount: 200,
      PaymentStatus: "success",
      PaymentMethod: "upi",
      PaymentDate: new Date(),
      CurrencyCode: "INR",
      GatewayResponse: "success",
      ErrorDescription: "none",
      ErrorCode: "0",
    };
    try {
      const response = await postData(API_PATHS.createPayment, requestBody);

      if (response.data.IsSuccess) {
        setShowModal(true);
        setMessage({
          heading: "Payment Successful",
          description:
            "Your payment is successful, please wait for admin approval before login.",
        });
      } else {
        if (response.data.Message === "Transaction already exists!") {
          setShowModal(true);
          setMessage({
            heading: "Payment Successful",
            description:
              "Payment is already done, please wait for admin approval before login.",
          });
        } else {
          setShowModal(true);
          setMessage({
            heading: "Something went wrong",
            description: "Please contact admin.",
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!state?.MemberId) {
      navigate(URL_PATH.login);
    }
  }, [state?.MemberId, navigate]);

  return (
    <section className="main-login">
      <div className="main-login-bg">
        <div className="container">
          <div className="payment-body">
            <h2 className="main-title">Pay Invoice</h2>
            <div className="payment-body-row">
              <div className="payment-icon">
                <span>
                  <img src="img/card-1.png" alt="" />
                </span>
                <span>
                  <img src="img/card-2.png" alt="" />
                </span>
                <span>
                  <img src="img/card-3.png" alt="" />
                </span>
                <span>
                  <img src="img/card-4.png" alt="" />
                </span>
              </div>
              <div>
                <h3 className="sub-title">
                  Payment amount: <span>200 Rs</span>
                </h3>
              </div>
              <div className="common-form">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name on card"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Card number"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Expiry date"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Security code"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        {isSubmitting ? (
                          <button
                            className="btn green-btn w-100 cursor-na"
                            disabled
                          >
                            <Spinner size="sm" />
                          </button>
                        ) : (
                          <button
                            className="btn green-btn"
                            onClick={handlePayment}
                          >
                            Pay <span>200 Rs</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        showModal={showModal}
        closeModal={closeModal}
        heading={message.heading}
        summary={message.description}
      /> */}
      <CenterModal
        show={showModal}
        onClose={closeModal}
        heading={message.heading}
        description={message.description}
      />
    </section>
  );
};

export default Payment;
