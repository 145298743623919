import React from 'react';

const CustomPagination = ({
    pageNumber,
    numberOfPages,
    setpageNumber,
}) => {
    const getPaginationRange = () => {
        if (numberOfPages <= 5) {
            return Array.from({ length: numberOfPages }, (_, i) => i);
        }

        let range = [];
        range.push(0);

        if (pageNumber > 1) {
            range.push('...');
        }

        if (pageNumber > 1 && pageNumber < numberOfPages - 2) {
            range.push(pageNumber);
        } else if (pageNumber === 1) {
            range.push(1, 2);
        } else if (pageNumber === numberOfPages - 2) {
            range.push(numberOfPages - 2);
        }

        if (pageNumber < numberOfPages - 2) {
            range.push('...');
        }

        range.push(numberOfPages - 1);

        return range;
    };

    const paginationRange = getPaginationRange();

    if (numberOfPages === 0 || pageNumber >= numberOfPages || numberOfPages === 1) {
        return <></>
    }

    return (
        <div className="pagination-body py-4">
            <ul>
                <li className="prev">
                    <button
                        className='text-nowrap'
                        onClick={(e) => {
                            e.preventDefault();
                            setpageNumber(Math.max(pageNumber - 1, 0));
                        }}
                    >
                        <i className="fas fa-chevron-left"></i> Prev
                    </button>
                </li>

                {paginationRange.map((item, index) =>
                    item === '...' ? (
                        <li key={index}>
                            <span className="delimiter">...</span>
                        </li>
                    ) : (
                        <li
                            key={index}
                            className={pageNumber === item ? 'active' : ''}
                        >
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setpageNumber(item);
                                }}
                            >
                                {item + 1}
                            </button>
                        </li>
                    )
                )}

                <li className="next">
                    <button
                        className='text-nowrap'
                        onClick={(e) => {
                            e.preventDefault();
                            setpageNumber(Math.min(pageNumber + 1, numberOfPages - 1));
                        }}
                    >
                        Next <i className="fas fa-chevron-right"></i>
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default CustomPagination;
