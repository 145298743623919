import React from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'

const CenterModal = ({
    heading,
    description,
    onClose,
    show,
    buttonText,
    isLoading,
    size
}) => {
    return (
        <Modal
            show={show}
            onHide={onClose}
            size={size ?? "lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {heading}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {description}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="bg-success" onClick={onClose}>{isLoading ? <Spinner size='sm' /> : buttonText ? buttonText : 'Close'}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CenterModal