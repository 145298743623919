import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { API_PATHS, errorMessages, URL_PATH } from '../Utils/Constants/constants';
import { MemberDetails } from '../Contexts/MemberDetailContext';
import CustomPopup from './CustomPopup';
import { getApiResponse, postData } from '../Utils/AxiosRequest';
import { useOutletContext } from 'react-router-dom';
import ConditionalLoader from './ConditionalLoader';
import deleteIcon from '../Assets/icons/delete.png';
import ConfirmationPopup from './ConfirmationPopup';
import SessionTimoutPopup from './SessionTimoutPopup';
import CenterModal from './CenterModal';

const EditGallery = ({
    profileId,
    showModal,
    disabled,
    closeModal
}) => {
    const { memberDetails } =
        useContext(MemberDetails);
    const memberId = memberDetails.MemberId;
    const [isDeleting, setIsDeleting] = useState(false);
    const [file, setFile] = useState([]);
    const [apiData, setApiData] = useState({});
    const [isLoadingData, setIsLoading] = useState(false);
    const [sessionTimeout, setSessionTimeout] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState('');
    const [pageError, setError] = useState("");
    const [showModalPopUp, setShowModalPopup] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState({
        heading: "",
        description: "",
    });
    const { updateToastMessage } = useOutletContext();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
    } = useForm();

    const formatReqBody = (data) => {
        const reqBody = {};
        reqBody.UploadedFile = Array.from(data.UploadedFile)
        reqBody.ProfileCatId = "00000000-0000-0000-0000-000000000000"
        reqBody.MemberId = memberId;
        reqBody.CreatedBy = memberId;
        reqBody.MemberMatrimonial_Id = profileId;
        return reqBody;
    };

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const reqBody = formatReqBody(data);
        if (file.length) {

            const params = `?MemberId${reqBody.MemberId}&ProfileCatId=${reqBody.ProfileCatId}&MemberMatrimonial_Id=${reqBody?.MemberMatrimonial_Id}&CreatedBy=${reqBody.CreatedBy}`
            try {
                const formData = new FormData();
                reqBody.UploadedFile.forEach((file) => {
                    formData.append('UploadedFile', file);
                })
                const response = await postData(API_PATHS.createGallery + params, formData, {
                    formData: true,
                    bearer: true
                });
                if (response.status === 200 && response.data.IsSuccess) {
                    updateToastMessage("Gallary updated successfully", "success");
                    setFile([])
                    setValue("UploadedFile", '');
                } else {
                    updateToastMessage(errorMessages.error, "danger");
                }
            } catch (error) {
                updateToastMessage(errorMessages.error, "danger");
            }
        }
        setIsSubmitting(false);
    };

    const closePopup = () => {
        setMessage({
            heading: "",
            description: "",
        });
        setShowModalPopup(false);
    };

    const deleteImage = (e, index) => {
        const dt = new DataTransfer();
        const files = getValues("UploadedFile");
        for (let i = 0; i < files.length; i++) {
            if (i != index)
                dt.items.add(files[i])
        }
        setFile(files => files.filter((_, i) => i !== index))
        setValue("UploadedFile", dt.files)
    }
    const removeImage = (e, id) => {
        setShowConfirmationPopup(id);
    }
    const cancelRemoveImage = () => {
        setShowConfirmationPopup('')
    }
    const confirmRemoveImage = async () => {
        setIsDeleting(true)
        const response = await getApiResponse(API_PATHS.deleteGallery + `?mgid=${showConfirmationPopup}`, true);
        setShowConfirmationPopup('')
        if (response.status === 200) {
            updateToastMessage("Image deleated successfully.", "success");

        } else if (response.status === 401) {
            setSessionTimeout(true);
        } else {
            updateToastMessage(errorMessages.error, "danger");
        }
        setIsDeleting(false)
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await getApiResponse(
                    `${API_PATHS.getMatrimonialGallery}?mmid=${profileId}`, true
                )
                if (response.status === 200) {
                    setApiData(response.data);
                } else if (response.status === 401) {
                    setSessionTimeout(true);
                }
            }
            catch (error) {
                setError(errorMessages.error);
            }
            setIsLoading(false);
        }
        if (showModal && !isDeleting && !isSubmitting)
            fetchData();
    }, [profileId, isDeleting, isSubmitting])

    return (
        <CustomPopup showModal={showModal} closeModal={closeModal}>
            <SessionTimoutPopup isSessionTimout={sessionTimeout}>
                <h4 className="sub-title">Gallery</h4>
                <div>
                    <div className='p-2'>
                        <h4 className='h5'>Current Images</h4>
                        <div style={{
                            minHeight: "200px"
                        }}>
                            <ConditionalLoader isLoading={isLoadingData} setError={pageError} noPref>
                                {apiData.length ? <ul className="d-flex justify-content-start list-inline gap-4 m-0"
                                    style={{
                                        maxWidth: "100%",
                                        overflowX: "auto"
                                    }}>
                                    {apiData.map((gallery, index) => <li key={index} className='p-2'>
                                        <div className="profile-img" style={{
                                            minWidth: "200px",
                                            maxWidth: "220px"
                                        }}>
                                            <img src={gallery?.DisplayURL} alt="" />
                                        </div>
                                        <button className='btn w-100 brn-sm bg-danger'
                                            onClick={(e) => { e.preventDefault(); removeImage(e, gallery?.MemberGalleryId) }}
                                        ><img height={24} width={24} src={deleteIcon} alt="delete" /></button>
                                    </li>)}
                                </ul> : <div className='d-flex justify-content-center align-items-center h-100'> <p className='text-center h-100 p-0'>Gallery not available</p></div>}
                            </ConditionalLoader>
                        </div>
                    </div>
                    {!disabled &&
                        <>
                            <hr className='m-0' />
                            <form role="form" className="common-form pt-2" onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        {file.length ? <ul className="d-flex justify-content-start list-inline gap-4 wrap m-0"
                                            style={{
                                                maxWidth: "100%",
                                                overflowX: "auto"
                                            }}
                                        >
                                            {file.map((url, index) => <li key={index} className='p-2'>
                                                <div className="profile-img" style={{
                                                    minWidth: "200px",
                                                    maxWidth: "220px"
                                                }}>
                                                    <img src={url} alt="" />
                                                </div>
                                                <button className='btn w-100 brn-sm bg-danger'
                                                    onClick={(e) => { e.preventDefault(); deleteImage(e, index) }}
                                                ><img height={24} width={24} src={deleteIcon} alt="delete" /></button>
                                            </li>)}
                                        </ul> : <h4 className='h5 mb-4'>Add Image</h4>}
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        <div className="form-group">
                                            <div className="custom-file" style={{
                                                zIndex: 0
                                            }}>
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    accept="image/*"
                                                    id="customFile"
                                                    {...register("UploadedFile", {
                                                        onChange: (e) => {
                                                            const arr = [];
                                                            for (let i = 0; i < e.target.files.length; i++) {
                                                                arr.push(URL.createObjectURL(e.target.files[i]))
                                                            }
                                                            setFile(arr)
                                                        }
                                                    })}
                                                    multiple={true}
                                                    disabled={isSubmitting}
                                                />
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="customFile"
                                                >
                                                    Upload Your Image
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="col-sm-12 col-lg-6 list-inline text-right">
                                        <li>
                                            <button
                                                type="submit"
                                                className="btn green-btn"
                                                disabled={file.length ? false : true}
                                            >
                                                Submit
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </>
                    }
                </div>
                <ConfirmationPopup showModal={showConfirmationPopup} closeModal={cancelRemoveImage}
                    confirmCallback={confirmRemoveImage}
                    denyCallback={cancelRemoveImage}
                    heading={"Confirm delete"} />
                <CenterModal
                    show={showModalPopUp}
                    onClose={closePopup}
                    heading={message.heading}
                    description={message.description}
                    buttonText={"Ok"}
                />
            </SessionTimoutPopup>
        </CustomPopup >
    )
}

export default EditGallery