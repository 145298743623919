import React, { useContext, useEffect, useState } from "react";
import { MemberDetails } from "../../Contexts/MemberDetailContext";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { API_PATHS, errorMessages, URL_PATH } from "../../Utils/Constants/constants";
import ConditionalLoader from "../../Components/ConditionalLoader";
import { getApiResponse } from "../../Utils/AxiosRequest";
import ImageSubstitute from "../../Components/ImageSubstitute";
import Modal from "../../Components/Modal";
import SessionTimoutPopup from "../../Components/SessionTimoutPopup";
import CenterModal from "../../Components/CenterModal";

const ViewMatrimonialProfile = () => {
  const { memberDetails } = useContext(MemberDetails);
  const [apiData, setApiData] = useState({});
  const [partnerDeatils, setPartnerDetails] = useState({});
  const [isLoadingDetails, setisLoadingDetails] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGallery, setIsLoadingGallery] = useState(true);
  const [sessionTimeout, setSessionTimeout] = useState(false);
  const [galleryData, setGalleryData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [galaryLoadError, setGalaryLoadError] = useState('');
  const [preferenceError, setPreferenceError] = useState('');
  const [loadError, setLoadError] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const memberId = memberDetails?.MemberId;
  const profileMemberId = apiData?.MemberId;

  const closePopup = () => {
    setShowModal(false);
    setMessage({
      heading: "",
      description: "",
    });
  };

  const fetchProfile = async () => {
    if (!id) {
      setLoadError(errorMessages.error);
      return
    }
    setisLoadingDetails(true);
    setIsLoadingGallery(true);
    const [profileResponse, galleryResponse] = await Promise.all([
      getApiResponse(
        `${API_PATHS.getMatrimonialProfile}?profileid=${id}`, true
      ),
      getApiResponse(
        `${API_PATHS.getMatrimonialGallery}?mmid=${id}`, true
      ),
    ])
    if (profileResponse.status === 200) {
      setApiData(profileResponse.data)
    } else {
      setLoadError(errorMessages.error);
    }

    if (galleryResponse.status === 200) {
      setGalleryData(galleryResponse.data)
    } else {
      setGalaryLoadError(errorMessages.error);
    }

    if (profileResponse.status === 401 && galleryResponse.status === 401) {
      setSessionTimeout(true);
    }
    setisLoadingDetails(false);
    setIsLoadingGallery(false);
  }
  const fetchData = async () => {
    if (!id) {
      setPreferenceError(errorMessages.error);
      return
    }
    setIsLoading(true);
    try {
      const response = await getApiResponse(
        `${API_PATHS.getPartnerPreference}?mmpid=${id}`, true
      )
      if (response.status === 200) {
        setPartnerDetails(response.data);
      }
    }
    catch (error) {
      setPreferenceError(errorMessages.error);
    }
    setIsLoading(false);
  }
  const fetchGallery = async () => {
    if (!id) {
      setGalaryLoadError(errorMessages.error);
      return
    }
    setIsLoadingGallery(true);
    try {
      const response = await getApiResponse(
        `${API_PATHS.getMatrimonialGallery}?mmid=${id}`, true
      )
      if (response.status === 200) {
        setApiData(response.data);
      } else if (response.status === 401) {
        setSessionTimeout(true);
      }
    }
    catch (error) {
      setGalaryLoadError(errorMessages.error);
    }
    setIsLoadingGallery(false);
  }

  useEffect(() => {
    if (pathname !== URL_PATH.memberprofile && id && (activeTab === 0 || activeTab === 1) && !apiData.MemberId) {
      fetchProfile()
    }
    if (id && activeTab === 2) {
      fetchData();
    }
  }, [activeTab]);

  return (
    <ConditionalLoader isLoading={isLoadingDetails} error={loadError}>
      <SessionTimoutPopup isSessionTimout={sessionTimeout}>
        <div className="view-member-profile">
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="row">
                <div className="col-md-3">
                  <div className="profile-img">
                    {apiData.DisplayURL && apiData.DisplayURL !== `${process.env.REACT_APP_API_URL}/` ? <img src={apiData?.DisplayURL} alt="" /> :
                      <ImageSubstitute name={apiData?.FirstName} />}
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="profile-detail">
                    <div className="mb-4">
                      <h2 className="sub-title-2">{apiData?.FirstName + ' ' + apiData?.LastName}</h2>
                      {/* <h3 className="sub-title-3">{apiData?.CurrentDesignation}</h3> */}
                    </div>
                    {memberId === profileMemberId ?
                      <div>
                        <Link
                          to={apiData.IsApproved === null ? URL_PATH.editMatrimonialProfile + `/${id}` : '#'}
                          className={`btn green-btn ${apiData.IsApproved !== null ? 'disabled' : ''}`}
                        >
                          Edit Profile
                        </Link>
                      </div> : <></>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div>
                <ul className="nav nav-tabs">
                  <li className="nav-item" >
                    <a className={`nav-link ${activeTab === 0 ? "active" : ""}`} onClick={(e) => { e.preventDefault(); setActiveTab(0) }} href="#tab-1">
                      Personal Detail
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${activeTab === 1 ? "active" : ""}`} onClick={(e) => { e.preventDefault(); setActiveTab(1) }} href="#tab-2">
                      Address Detail
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${activeTab === 2 ? "active" : ""}`} onClick={(e) => { e.preventDefault(); setActiveTab(2) }} href="#tab-3">
                      Partner Detail
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  {activeTab === 0 ? <div className={`tab-pane container ${activeTab === 0 ? "active" : "fade"}`} id="tab-1">
                    <div className="view-member-profile-detail">
                      <ul className="view-profile-list">
                        <li>
                          <strong>First Name: </strong> <span>{apiData?.FirstName}</span>
                        </li>
                        <li>
                          <strong>Last Name: </strong> <span>{apiData?.LastName}</span>
                        </li>
                        <li>
                          <strong>Gender: </strong> <span>{apiData?.Gender}</span>
                        </li>
                        <li>
                          <strong>Email Address: </strong>{" "}
                          <span>{apiData.Email}</span>
                        </li>
                        <li>
                          <strong>Birth day: </strong> <span>{apiData.DOB}</span>
                        </li>
                        <li>
                          <strong>Mobile No: </strong> <span>{apiData?.PhoneNumber}</span>
                        </li>
                        <li>
                          <strong>Religion: </strong> <span>{apiData?.Religion}</span>
                        </li>
                        <li>
                          <strong>Sub-Caste: </strong> <span>{apiData?.SubCaste}</span>
                        </li>
                        <li>
                          <strong>Height: </strong> <span>{apiData?.Height}</span>
                        </li>
                        <li>
                          <strong>Weight: </strong> <span>{apiData?.Weight}</span>
                        </li>
                      </ul>
                    </div>
                  </div> : <></>}
                  {activeTab === 1 ?
                    <div className={`tab-pane container ${activeTab === 1 ? "active" : "fade"}`} id="tab-2">
                      <div className="view-member-profile-detail">
                        <ul className="view-profile-list">
                          <li>
                            <strong>Address-1: </strong>{" "}
                            <span>{apiData?.AddressLine1}</span>
                          </li>
                          <li>
                            <strong>Address-2: </strong>{" "}
                            <span>{apiData?.AddressLine2}</span>
                          </li>
                          <li>
                            <strong>City: </strong> <span>{apiData?.CityName} </span>
                          </li>
                          <li>
                            <strong>State: </strong> <span>{apiData?.StateName}</span>
                          </li>
                          <li>
                            <strong>Pincode: </strong> <span>{apiData?.Pincode}</span>
                          </li>
                          <li>
                            <strong>Phone No: </strong> <span>{apiData?.PhoneNumber}</span>
                          </li>
                        </ul>
                      </div>
                    </div> :
                    <div className={`tab-pane container ${activeTab === 2 ? "active" : "fade"}`} id="tab-3">
                      <ConditionalLoader isLoading={isLoading} error={preferenceError} noPref>
                        <div className="view-member-profile-detail">
                          <ul className="view-profile-list">
                            <li>
                              <strong>Max-Height: </strong> <span>{partnerDeatils?.Height} Cm</span>
                            </li>
                            <li>
                              <strong>Max-Weight: </strong> <span>{partnerDeatils?.Weight} Kg</span>
                            </li>
                            <li>
                              <strong>Physically Disabled: </strong> <span> {partnerDeatils?.IsPhysicallyDisabled ? "Yes" : "No"}</span>
                            </li>
                            <li>
                              <strong>Sub Caste: </strong> <span> {partnerDeatils?.SubCaste}</span>
                            </li>
                          </ul>
                        </div>
                      </ConditionalLoader>
                    </div>}
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <h4 className="sub-title mb-4">Gallery</h4>
              <ConditionalLoader isLoading={isLoadingGallery} error={galaryLoadError}>
                <div className="gallery-module d-flex">
                  {
                    galleryData.length ? galleryData.map((item, index) => (
                      <div key={index} className="profile-img mr-2"
                        style={{
                          minHeight: "200px",
                          minWidth: "200px",
                          maxWidth: "220px",
                        }}>
                        <img src={item?.DisplayURL} alt="" />
                      </div>
                    )) : <p className="text-center">Gallery not available</p>
                  }
                </div>
              </ConditionalLoader>
            </div>
          </div>
          <CenterModal
            show={showModal}
            onClose={closePopup}
            heading={message.heading}
            description={message.description}
          />
        </div>
      </SessionTimoutPopup>
    </ConditionalLoader>
  );
};

export default ViewMatrimonialProfile;
