import React, { useContext, useEffect, useState } from "react";
import { MemberDetails } from "../../Contexts/MemberDetailContext";
import { Link, useLocation, useParams } from "react-router-dom";
import { API_PATHS, errorMessages, URL_PATH } from "../../Utils/Constants/constants";
import { getDateTime } from "../../Utils/format";
import ConditionalLoader from "../../Components/ConditionalLoader";
import { getApiResponse } from "../../Utils/AxiosRequest";
import ImageSubstitute from "../../Components/ImageSubstitute";

const MembershipProfile = () => {
  const { isLoading, memberDetails, education, workExperience, error } =
    useContext(MemberDetails);

  const [localMemberDetails, setLocalMemberDetails] = useState({})
  const [localEducation, setLocalEducation] = useState([])
  const [localWorkExperience, setLocalWorkExperience] = useState([])
  const [isLoadingDetails, setisLoadingDetails] = useState(false);
  const [errorDetails, setErrorDetails] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const { pathname } = useLocation();
  const { id } = useParams();

  const memberDetailsMap = id ? { ...localMemberDetails } : { ...memberDetails };
  const educationMap = id ? [...localEducation] : [...education];
  const workExperienceMap = id ? [...localWorkExperience] : [...workExperience];

  const fetchProfile = async () => {
    setisLoadingDetails(true);
    const response = await getApiResponse(
      `${API_PATHS.getMember}?memberid=${id}`, true
    );
    if (response.status === 200) {
      setLocalMemberDetails(response.data);
      setLocalEducation(response.data.MemberEducation ?? []);
      setLocalWorkExperience(response.data.MemberExperience ?? []);
    } else {
      setErrorDetails(errorMessages.error);
    }
    setisLoadingDetails(false);
  }

  useEffect(() => {
    if (pathname !== URL_PATH.memberprofile && id) {
      fetchProfile()
    }
  }, []);

  return (
    <ConditionalLoader isLoading={isLoading || isLoadingDetails} error={error ?? errorDetails} refresh={id ? fetchProfile : null}>
      <div>
        <div className="view-member-profile">
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="row">
                <div className="col-md-3">
                  <div className="profile-img">
                    {memberDetailsMap?.DisplayURL && memberDetailsMap.DisplayURL !== `${process.env.REACT_APP_API_URL}/` ? <img src={memberDetailsMap.DisplayURL} alt="" /> :
                      <ImageSubstitute name={memberDetailsMap?.FirstName ?? ''} />
                    }
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="profile-detail">
                    <div className="mb-4">
                      <h2
                        className="sub-title-2"
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {`${memberDetailsMap.FirstName} ${memberDetailsMap.LastName}` ??
                          ""}
                      </h2>
                      <h3 className="sub-title-3">
                        {memberDetailsMap?.CurrentDesignation ?? ""}
                      </h3>
                    </div>
                    {!id &&
                      <div>
                        <Link
                          to={URL_PATH.memberprofileedit}
                          className="btn green-btn"
                        >
                          Edit Profile
                        </Link>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div>
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className={`nav-link ${activeTab === 0 ? "active" : ""}`} onClick={(e) => { e.preventDefault(); setActiveTab(0) }} href="#tab-1"
                    >
                      Personal Info
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${activeTab === 1 ? "active" : ""}`} onClick={(e) => { e.preventDefault(); setActiveTab(1) }} href="#tab-2">
                      Education Detail
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${activeTab === 2 ? "active" : ""}`} onClick={(e) => { e.preventDefault(); setActiveTab(2) }} href="#tab-3">
                      Work Exp Details
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  {activeTab === 0 ? <div className={`tab-pane container ${activeTab === 0 ? "active" : "fade"}`} id="tab-1">
                    <div className="view-member-profile-detail">
                      <ul className="view-profile-list">
                        <li>
                          <strong>Full Name: </strong>{" "}
                          <span
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {`${memberDetailsMap.FirstName} ${memberDetailsMap.LastName}` ??
                              "NA"}
                          </span>
                        </li>
                        <li>
                          <strong>Date of Birth: </strong>{" "}
                          <span>
                            {new Date(memberDetailsMap?.DOB).toLocaleDateString(
                              "en-US"
                            ) ?? "NA"}
                          </span>
                        </li>
                        <li>
                          <strong>Gender: </strong>{" "}
                          <span>{memberDetailsMap?.Gender ?? "N/A"} </span>
                        </li>
                        <li>
                          <strong>Occupation: </strong>{" "}
                          <span>{memberDetailsMap?.CurrentDesignation ?? "N/A"} </span>
                        </li>
                        <li>
                          <strong>Nationality: </strong>{" "}
                          <span> {memberDetailsMap?.Nationality ?? "N/A"}</span>
                        </li>
                        <li>
                          <strong>City: </strong>{" "}
                          <span> {memberDetailsMap?.CityName ?? "N/A"}</span>
                        </li>
                        <li>
                          <strong>State: </strong>{" "}
                          <span> {memberDetailsMap?.State ?? "N/A"}</span>
                        </li>
                        <li>
                          <strong>Country: </strong>{" "}
                          <span> {memberDetailsMap?.Country ?? "N/A"}</span>
                        </li>
                        <li>
                          <strong>Address Line 1: </strong>{" "}
                          <span> {memberDetailsMap?.AddressLine1 ?? "N/A"}</span>
                        </li>

                        <li>
                          <strong>Address Line 2: </strong>{" "}
                          <span> {memberDetailsMap?.AddressLine2 ?? "N/A"}</span>
                        </li>
                        <li>
                          <strong>Phone Number: </strong>{" "}
                          <span>{memberDetailsMap?.PhoneNumber ?? "N/A"}</span>
                        </li>
                        <li>
                          <strong>Email Address: </strong>{" "}
                          <span>{memberDetailsMap?.Email ?? "N/A"}</span>
                        </li>
                      </ul>
                    </div>
                  </div> : activeTab === 1 ?
                    <div className={`tab-pane container ${activeTab === 1 ? "active" : "fade"}`} id="tab-2">
                      <div className="view-member-profile-detail">
                        {educationMap.length ? (
                          educationMap.map((item, index) => (
                            <>
                              <ul
                                key={item.MemberEducationId}
                                className="view-profile-list"
                              >
                                {item?.DegreeName && (
                                  <li>
                                    <strong>Degree: </strong>{" "}
                                    <span>{item?.DegreeName ?? "N/A"}</span>
                                  </li>
                                )}
                                {item?.DegreeDescription && (
                                  <li>
                                    <strong>Description: </strong>{" "}
                                    <span>{item?.DegreeDescription ?? "N/A"}</span>
                                  </li>
                                )}
                                {item?.InstitutionName && (
                                  <li>
                                    <strong>University/College Name: </strong>{" "}
                                    <span>{item?.InstitutionName ?? "N/A"}</span>
                                  </li>
                                )}
                                {item?.FieldOfStudy && (
                                  <li>
                                    <strong>Field of Study: </strong>{" "}
                                    <span>{item?.FieldOfStudy ?? "N/A"}</span>
                                  </li>
                                )}
                                {item?.PassingYear && (
                                  <li>
                                    <strong>Passing Year: </strong>{" "}
                                    <span>{item?.PassingYear ?? "N/A"}</span>
                                  </li>
                                )}
                                {item?.GradeName && (
                                  <li>
                                    <strong>Grade: </strong>{" "}
                                    <span>{item?.GradeName ?? "N/A"}</span>
                                  </li>
                                )}
                                {item?.PassingYear && (
                                  <li>
                                    <strong>Grade: </strong>{" "}
                                    <span>{item?.PassingYear ?? "N/A"}</span>
                                  </li>
                                )}
                                {item?.DegreePercentage !== undefined && (
                                  <li>
                                    <strong>Percentage: </strong>{" "}
                                    <span>{item?.DegreePercentage ?? "N/A"}</span>
                                  </li>
                                )}
                              </ul>
                              {index !== educationMap.length - 1 && <hr />}
                            </>
                          ))
                        ) : (
                          <>
                            <p>Education details not available</p>
                            {!id && <Link to={URL_PATH.memberprofileedit}>
                              Edit Profile
                            </Link>}
                          </>
                        )}
                      </div>
                    </div> :
                    <div className={`tab-pane container ${activeTab === 2 ? "active" : "fade"}`} id="tab-3">
                      <div className="view-member-profile-detail">
                        {workExperienceMap.length ? (
                          workExperienceMap.map((item) => (
                            <ul
                              key={item.MemberExperienceId}
                              className="view-profile-list"
                            >
                              <li>
                                <strong>Job Title: </strong>{" "}
                                <span>{item?.JobTitle ?? "N/A"}</span>
                              </li>
                              <li>
                                <strong>Company Name: </strong>{" "}
                                <span>{item?.CompanyName ?? "N/A"}</span>
                              </li>
                              <li>
                                <strong>Employment Nature: </strong>{" "}
                                <span> {item?.EmploymentNature ?? "N/A"}</span>
                              </li>
                              <li>
                                <strong>Start Date: </strong>{" "}
                                <span>{getDateTime(item?.StartDate) ?? "N/A"}</span>
                              </li>
                              <li>
                                <strong>End Date: </strong>{" "}
                                <span> {getDateTime(item?.EndDate) ?? "N/A"}</span>
                              </li>
                              <li>
                                <strong>Job Location: </strong>{" "}
                                <span> {item?.JobLocation ?? "N/A"}</span>
                              </li>
                              <li>
                                <strong>Leaving Reason: </strong>{" "}
                                <span> {item?.LeavingReason ?? "N/A"}</span>
                              </li>
                            </ul>
                          ))
                        ) : (
                          <>
                            <p>Work experience not available</p>
                            {!id && < Link to={URL_PATH.memberprofileedit}>
                              Edit Profile
                            </Link>}
                          </>
                        )}
                      </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConditionalLoader >
  );
};

export default MembershipProfile;
