import React from 'react'

const ImageSubstitute = ({ name, background, sm, xs }) => {
    name = name ?? '';
    return (
        <div className='h-100 w-100 d-flex justify-content-center align-items-center m-0' style={{ fontSize: xs ? "1rem" : sm ? '2rem' : '4rem', color: 'white', background: background ?? 'linear-gradient(225deg, #9EEB47, #019040)' }}>
            <span style={{
                textShadow: "1px 1px #3a3a3a"
            }}>{name.toUpperCase().slice(0, 2)}</span>
        </div >
    )
}

export default ImageSubstitute