import React from "react";
import { Modal } from "react-bootstrap";

const CustomPopup = ({
    showModal,
    closeModal,
    heading,
    children,
    className,
    extraPadd,
    size,
    noCross
}) => {
    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            aria-labelledby="contained-modal-title-vcenter"
            size={size ?? "lg"}
        >
            {heading && <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {heading}
                </Modal.Title>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => closeModal(false)}
                >
                    &times;
                </button>
            </Modal.Header>}
            <Modal.Body>
                {(!heading && !noCross) ? <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => closeModal(false)}
                >
                    &times;
                </button> : <></>}
                <div className="row">
                    <div className={"col-md-12"}>
                        <div className="founder-detail">
                            {children}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CustomPopup;
