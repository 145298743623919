import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../Components/InputField";
import { postData } from "../../Utils/AxiosRequest";
import {
  API_PATHS,
  errorMessages,
  URL_PATH,
} from "../../Utils/Constants/constants";
import { Spinner } from "react-bootstrap";
import CenterModal from "../../Components/CenterModal";
import { LoginContext } from "../../Contexts/LoginContext";
import { clearSession, createSession } from "../../Utils/Auth/session";

const Login = () => {
  const [data, setData] = useState({
    Email: "",
    Password: "",
    rememberMe: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData((prvs) => {
      const newData = { ...prvs, [name]: value };
      return newData;
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!data.Email || !data.Password) {
      return;
    }
    setIsSubmitting(true);
    const reqBody = {
      Email: data.Email.trim(),
      Password: data.Password.trim(),
    };

    try {
      const response = await postData(API_PATHS.loginMember, reqBody);

      if (response.status === 200) {
        if (response.data.IsSuccess) {
          clearSession();
          const responseData = response?.data?.Response;
          const token = responseData?.MemberToken ?? "";
          const id = responseData?.MemberId ?? "";
          if (!token || !id) {
            setShowModal(true);
            setMessage({
              heading: "Somethig went worong",
              description: "Please try again later",
            });
          }
          if (responseData?.IsPaymentCompleted && responseData?.IsApproved) {
            createSession(token, id, data.rememberMe);
            setIsLoggedIn(true);
            navigate(URL_PATH.memberhome);
          } else {
            navigate(URL_PATH.payment, {
              state: { MemberId: id },
            });
          }
        } else {
          const responseMessage = response.data.Message ?? "";
          switch (responseMessage) {
            case "Member Email OR Password is Mismatched!":
              setShowModal(true);
              setMessage({
                heading: "Invalid Cradentials",
                description: "Please check your email and password",
              });
              break;
            case "Member is Rejected!":
              setShowModal(true);
              setMessage({
                heading: "Application rejected by admin.",
                description: "Please contact admin.",
              });
              break;
            case "Member Does not Exists!":
              setShowModal(true);
              setMessage({
                heading: "Invalid Credentials",
                description: "Email not registered",
              });
              break;
            case "Member`s approval is pending!":
              setShowModal(true);
              setMessage({
                heading: "Approved Pending",
                description: "Approval is Pending. Please contact admin.",
              });
              break;
            default:
              setShowModal(true);
              setMessage({
                heading: errorMessages.error,
                description: errorMessages.errorDescription,
              });
              break;
          }
          setData({
            Email: data.Email,
            Password: "",
            rememberMe: data.rememberMe,
          })
        }
      }
    } catch (error) {
      console.error(error);
      setShowModal(true);
      setMessage({
        heading: errorMessages.error,
        description: errorMessages.errorDescription,
      });
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate(URL_PATH.memberhome);
    }
  }, [isLoggedIn]);

  return (
    <section className="main-login">
      <div className="main-login-bg">
        <div className="container">
          <div className="login-container">
            <h3 className="main-title mb-3">Login</h3>
            <div className="common-form">
              <form onSubmit={handleLogin}>
                <div className="form-group">
                  <InputField
                    type="email"
                    placeholder="User Name"
                    name="Email"
                    value={data.Email}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group">
                  <InputField
                    type="password"
                    placeholder="Password"
                    name="Password"
                    value={data.Password}
                    onChange={handleChange}
                    autoComplete="off"
                    required

                  />
                </div>
                <div className="checkgroup">
                  <div className="custom-control custom-checkbox">
                    <InputField
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck"
                      name="rememberMe"
                      value={data.rememberMe}
                      onChange={handleChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck"
                    >
                      Remember Me
                    </label>
                  </div>
                  <div>
                    <Link
                      to="/forgotpassword"
                      className="green-text text-underline"
                    >
                      Forget Password?
                    </Link>
                  </div>
                </div>
                <div className="form-group">
                  {isSubmitting ? (
                    <button className="btn green-btn  w-100 cursor-na" disabled>
                      <Spinner size="sm" />
                    </button>
                  ) : (
                    <input
                      type="submit"
                      className="btn green-btn  w-100"
                      value="Submit"
                    />
                  )}
                </div>
              </form>
              <div>
                Don’t Have any Account?{" "}
                <Link to="/membership" className="green-text font-weight-bold">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CenterModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        heading={message.heading}
        description={message.description}
        buttonText={"Try again"}
      />
    </section>
  );
};

export default Login;
