import React from 'react'
import CustomPopup from './CustomPopup'
import ImageSubstitute from './ImageSubstitute'

const ViewProfilePopup = ({
    show,
    details,
    onClose,
    heading,
    id
}) => {
    return (
        <CustomPopup className="modal-lg" showModal={show} closeModal={onClose} heading={heading}>
            <div className="view-member-profile-detail">
                {/* <div style={{
                    height: '150px',
                    width: '150px',
                    borderRadius: '100%',
                    overflow: 'hidden',
                    border: '5px solid #efefef'
                }}> */}
                {/* {details.DisplayURL && details.DisplayURL !== `${process.env.REACT_APP_API_URL}/` ? <img
                        src={details.DisplayURL}
                        className="card-img-top"
                        alt="Profile Image"
                    /> :
                        <ImageSubstitute name={details.FirstName} />
                    } */}
                <div className="profile-img"
                    style={{
                        width: "200px"
                    }}>
                    {details.DisplayURL && details.DisplayURL !== `${process.env.REACT_APP_API_URL}/` ? <img src={details?.DisplayURL} alt="" /> :
                        <ImageSubstitute name={details?.FirstName} />}
                </div>
                {/* </div> */}
                <ul className="view-profile-list">
                    <li>
                        <strong>Name: </strong>{" "}
                        <span>{(details.FirstName ?? "" + details?.LastName ?? "")} </span>
                    </li>
                    <li>
                        <strong>Gender: </strong>{" "}
                        <span>{details.Gender ?? "N/A"}</span>
                    </li>
                    <li>
                        <strong>Email: </strong> <span>{details.Email ?? ""}</span>
                    </li>
                    <li>
                        <strong>City: </strong>{" "}
                        <span>
                            {details?.CityName ?? "N/A"}
                        </span>
                    </li>
                    <li>
                        <strong>State: </strong>{" "}
                        <span>{details?.State ?? "N/A"}</span>
                    </li>
                </ul>
            </div>
        </CustomPopup>
    )
}

export default ViewProfilePopup