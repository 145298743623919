import React from "react";
import { getLimitHtmlContent } from "../../Utils/format";

const EventCard = ({ id, imgUrl, iconUrl, heading, summary, url }) => {
  return (
    <div className="awards-col">
      <div className="news-updates-itam">
        <div className="news-updates-itam-body">
          <div className="news-img">
            <img src={imgUrl} alt="slider" />
          </div>
          <div className="news-body award-bg">
            <div className="d-flex align-items-center">
              {iconUrl && (
                <div className="donation-icon">
                  <img src={iconUrl} alt="" />
                </div>
              )}
              <h3>{heading}</h3>
            </div>
            <div
              className="mw-100 hide-child"
              dangerouslySetInnerHTML={{
                __html: getLimitHtmlContent(summary, 100),
              }}
            ></div>
            {id && (
              <a
                title="Read More"
                className="main-link"
                tabIndex="0"
                href={url ?? "#"}
              >
                <i className="fas fa-arrow-right"></i> Read More
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
