import React from "react";
import { Spinner } from "react-bootstrap";

const ConditionalLoader = ({ isLoading, error, refresh, children, noPref }) => {

  if (error) {
    return (<div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ minHeight: "500px" }}
    >
      <h1>{error}</h1>
      {refresh ? (
        <button
          className="btn"
          onClick={() => {
            refresh();
          }}
        >
          Refresh
        </button>
      ) : (
        <p>Please try refreshing</p>
      )}
    </div>);
  }
  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={noPref ? {} : { minHeight: "500px" }}
      >
        <Spinner />
      </div>
    );
  }
  return <>{children}</>;
};

export default ConditionalLoader;
