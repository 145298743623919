import React from "react";
import Slider from "react-slick";

const Arrow = (props) => {
  const { className, style, onClick } = props;
  if (props.to == "prev") {
    return (
      <div className={"nav-arrow arrow-left slick-arrow"} onClick={onClick}>
        <i className="fas fa-arrow-left"></i>
      </div>
    );
  }
  return (
    <div className={"nav-arrow arrow-right slick-arrow"} onClick={onClick}>
      <i className="fas fa-arrow-right"></i>
    </div>
  );
};

const ButtonSlider = ({ children, className }) => {
  const settings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    dots: false,
    prevArrow: <Arrow to="prev" />,
    nextArrow: <Arrow to="next" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 676,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider className={className} {...settings}>
      {children.props.children}
    </Slider>
  );
};

export default ButtonSlider;
