import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { API_PATHS, errorMessages, URL_PATH } from '../Utils/Constants/constants';
import { MemberDetails } from '../Contexts/MemberDetailContext';
import CustomPopup from './CustomPopup';
import { getApiResponse, postData } from '../Utils/AxiosRequest';
import { checkIsSelected, checkOnlyCharacters, checkOnlyNumber } from '../Utils/validation/validators';
import ConditionalLoader from './ConditionalLoader';
import { preferenceKeys } from '../Utils/Constants/payload';
import { useNavigate, useOutletContext } from 'react-router-dom';
import CenterModal from './CenterModal';

const EditPartnerPreference = ({
    profileId,
    showModal,
    disabled,
    closeModal
}) => {
    const { memberDetails } =
        useContext(MemberDetails);
    const memberId = memberDetails.MemberId;
    const [apiData, setApiData] = useState({});
    const [isLoadingData, setIsLoading] = useState(false);
    const [pageError, setError] = useState("");
    const [categories, setCategories] = useState([]);
    const [maritalStatus, setMaritalStatus] = useState([]);
    const [religions, setReligions] = useState([]);
    const [bodyTypes, setBodyTypes] = useState([]);
    const [skinColor, setSkinColor] = useState([]);
    const [occupation, setOccupation] = useState([]);
    const [motherTounge, setMotherTounge] = useState([]);
    const [annualIncome, setAnnualIncome] = useState([]);

    const { updateToastMessage } = useOutletContext();

    const [showModalPopUp, setShowModalPopup] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState({
        heading: "",
        description: "",
    });
    const {
        register,
        handleSubmit,
        formState: { errors, reset, isDirty },
        setValue,
        getValues,
    } = useForm();
    const navigate = useNavigate();

    const formatReqBody = (data) => {
        const formData = { ...data };
        const reqBody = {};
        Object.keys(formData).forEach((key) => {
            let value = formData[key];
            if (
                key === "GenderId" ||
                key === "CurrentDesignationId" ||
                key === "NationalityId" ||
                key === "CountryId" ||
                key === "StateId" ||
                key === "DistrictId" ||
                key === "CategoryId" ||
                key === "MaritalStatusId" ||
                key === "ReligionId" ||
                key === "BodyTypeId" ||
                key === "SkinColorId" ||
                key === "OccupationId" ||
                key === "MotherTongueId" ||
                key === "ProfileCategoryId" ||
                key === "Annual_IncomeId" ||
                key === "FatherOccupationId" ||
                key === "MotherOccupationId"
            ) {
                if (value === "0") {
                    reqBody[key] = "";
                } else {
                    reqBody[key] = value;
                }
            } else if (key === "IsPhysicallyDisabled") {
                reqBody[key] = value === "1" ? true : false;
            } else if (typeof value === "string") {
                reqBody[key] = value.trim();
            } else {
                reqBody[key] = value;
            }
        });
        reqBody.MemberId = memberId;
        reqBody.CreatedBy = memberId;
        reqBody.MemberMatrimonial_Id = profileId
        reqBody.ModifiedBy = memberId;
        return reqBody;
    };
    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const reqBody = formatReqBody(data);
        try {
            const response = await postData(API_PATHS.updatePartnerPreference, reqBody, {
                bearer: true
            });
            switch (response.status) {
                case 200:
                    if (response.data.IsSuccess) {
                        updateToastMessage("Partner Preference Updated Successfully", "success");
                    } else {
                        updateToastMessage(errorMessages.error, "danger");
                    }
                    break;
                case 400:
                    updateToastMessage("Validation error.", "danger");

                    break;
                default:
                    updateToastMessage(errorMessages.error, "danger");

            }
        } catch (error) {
            console.error(error);
            updateToastMessage(errorMessages.error, "danger");

        }
        setIsSubmitting(false);
    };

    const closePopup = () => {
        setMessage({
            heading: "",
            description: "",
        });
        setShowModalPopup(false);
    };
    const mapDataFromAPI = async (apiData) => {
        Object.keys(apiData).forEach((key) => {
            if (preferenceKeys.includes(key)) {
                if (apiData[key] === "00000000-0000-0000-0000-000000000000") {
                    setValue(key, "0");
                } else if (key === "IsPhysicallyDisabled") {
                    setValue(key, apiData[key] ? "1" : "2");
                } else {
                    setValue(key, apiData[key] ?? "");
                }
            }
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const [
                    maritalStatusResponse,
                    relegionResponse,
                    bodyTypeResponse,
                    skinColorResponse,
                    occupationResponse,
                    annualIncomeResponse,
                    motherToungeResponse,
                    categoriesResponse,
                    profileResponse
                ] = await Promise.all([
                    getApiResponse(API_PATHS.getMaritalStatus),
                    getApiResponse(API_PATHS.getRelegios),
                    getApiResponse(API_PATHS.getBodyType),
                    getApiResponse(API_PATHS.getComplexions),
                    getApiResponse(API_PATHS.getOccupations),
                    getApiResponse(API_PATHS.getAnnualIncomes),
                    getApiResponse(API_PATHS.getMotherTounges),
                    getApiResponse(API_PATHS.getCategories),
                    getApiResponse(
                        `${API_PATHS.getPartnerPreference}?mmpid=${profileId}`, true
                    )
                ])
                if (categoriesResponse.status === 200) {
                    setCategories(categoriesResponse.data ?? []);
                }
                if (maritalStatusResponse.status === 200) {
                    setMaritalStatus(maritalStatusResponse.data ?? []);
                }
                if (bodyTypeResponse.status === 200) {
                    setBodyTypes(bodyTypeResponse.data ?? []);
                }
                if (relegionResponse.status === 200) {
                    setReligions(relegionResponse.data ?? []);
                }
                if (skinColorResponse.status === 200) {
                    setSkinColor(skinColorResponse.data ?? []);
                }
                if (motherToungeResponse.status === 200) {
                    setMotherTounge(motherToungeResponse.data ?? []);
                }
                if (occupationResponse.status === 200) {
                    setOccupation(occupationResponse.data ?? []);
                }
                if (annualIncomeResponse.status === 200) {
                    setAnnualIncome(annualIncomeResponse.data ?? []);
                }
                if (profileResponse.status === 200) {
                    setApiData(profileResponse.data);
                    await mapDataFromAPI(profileResponse.data)
                }
            } catch (error) {
                setError(errorMessages.error);
            }
            setIsLoading(false);
        };
        if (showModal) {
            fetchData();
        }
    }, [showModal, profileId])

    if (disabled) {
        navigate(URL_PATH.matrimonialprofiles + `/${profileId}`);
    }

    return (
        <CustomPopup showModal={showModal} heading={"Edit Preference"} closeModal={closeModal}>
            <ConditionalLoader isLoading={isLoadingData} setError={pageError}>
                <div>
                    <form role="form" className="common-form" onSubmit={handleSubmit(onSubmit)}>
                        <h4 className="sub-title mb-4">Partner Preference</h4>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <select
                                        className={`custom-select ${errors.MaritalStatusId && isDirty
                                            ? "red-placeholder border border-danger"
                                            : ""
                                            }`}
                                        placeholder={
                                            errors.MaritalStatusId && isDirty
                                                ? errors.MaritalStatusId.message
                                                : "Marital Status"
                                        }
                                        defaultValue={"0"}
                                        {...register("MaritalStatusId", {
                                            required: "Please select Marital Status",
                                            validate: (v) => checkIsSelected(v, "Marital Status"),
                                        })}
                                        disabled={isSubmitting}
                                    >
                                        <option key={0} value={"0"}>
                                            Marital Status
                                        </option>
                                        {maritalStatus.length > 0 &&
                                            maritalStatus.map((item) => (
                                                <option
                                                    key={item.MaritalStatusId}
                                                    value={item.MaritalStatusId}
                                                >
                                                    {item.MaritalStatusDesc.toUpperCase()}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <select
                                        className={`custom-select ${errors.CasteId && isDirty
                                            ? "red-placeholder border border-danger"
                                            : ""
                                            }`}
                                        placeholder={
                                            errors.CasteId && isDirty
                                                ? errors.CasteId.message
                                                : "Caste"
                                        }
                                        defaultValue={"0"}
                                        {...register("CasteId", {
                                            required: "Please select Caste",
                                            validate: (v) => checkIsSelected(v, "Caste"),
                                        })}
                                        disabled={isSubmitting}
                                    >
                                        <option key={0} value={"0"}>
                                            Caste
                                        </option>
                                        {categories.length > 0 &&
                                            categories.map((item) => (
                                                <option
                                                    key={item.CategoryId}
                                                    value={item.CategoryId}
                                                >
                                                    {item.CategoryDesc.toUpperCase()}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <select
                                        className={`custom-select ${errors.Annual_IncomeId && isDirty
                                            ? "red-placeholder border border-danger"
                                            : ""
                                            }`}
                                        placeholder={
                                            errors.Annual_IncomeId && isDirty
                                                ? errors.Annual_IncomeId.message
                                                : "Annual Income"
                                        }
                                        defaultValue={"0"}
                                        {...register("Annual_IncomeId", {
                                            required: "Please select Annual Income",
                                            validate: (v) => checkIsSelected(v, "Annual Income"),
                                        })}
                                        disabled={isSubmitting}
                                    >
                                        <option key={0} value={"0"}>
                                            Annual Income
                                        </option>
                                        {annualIncome.length > 0 &&
                                            annualIncome.map((item) => (
                                                <option
                                                    key={item.Annual_IncomeId}
                                                    value={item.Annual_IncomeId}
                                                >
                                                    {item.Annual_IncomeDesc.toUpperCase()}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <select
                                        className={`custom-select ${errors.ReligionId && isDirty
                                            ? "red-placeholder border border-danger"
                                            : ""
                                            }`}
                                        placeholder={
                                            errors.ReligionId && isDirty
                                                ? errors.ReligionId.message
                                                : "Religion"
                                        }
                                        defaultValue={"0"}
                                        {...register("ReligionId", {
                                            required: "Please select Religion",
                                            validate: (v) => checkIsSelected(v, "Religion"),
                                        })}
                                        disabled={isSubmitting}
                                    >
                                        <option key={0} value={"0"}>
                                            Religion
                                        </option>
                                        {religions.length > 0 &&
                                            religions.map((item) => (
                                                <option
                                                    key={item.ReligionId}
                                                    value={item.ReligionId}
                                                >
                                                    {item.ReligionDesc.toUpperCase()}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={`form-control ${errors.SubCaste && isDirty
                                            ? "red-placeholder border border-danger"
                                            : ""
                                            }`}
                                        placeholder={
                                            errors.SubCaste && isDirty
                                                ? errors.SubCaste.message
                                                : "Sub-Caste"
                                        }
                                        autoComplete="off"
                                        {...register("SubCaste", {
                                            required: "Sub-Caste is required",
                                            validate: (v) =>
                                                checkOnlyCharacters(v, "Sub-Caste"),
                                        })}
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={`form-control ${errors.Height && isDirty
                                            ? "red-placeholder border border-danger"
                                            : ""
                                            }`}
                                        placeholder={
                                            errors.Height && isDirty
                                                ? errors.Height.message
                                                : "Height in CM"
                                        }
                                        autoComplete="off"
                                        {...register("Height", {
                                            required: "Height is required",
                                            min: {
                                                value: 50,
                                                message: "Please enter a valid height in cm",
                                            },
                                            max: {
                                                value: 250,
                                                message: "Please enter a valid height in cm",
                                            },
                                            validate: (v) => checkOnlyNumber(v, "Height")
                                        })}
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={`form-control ${errors.Weight && isDirty
                                            ? "red-placeholder border border-danger"
                                            : ""
                                            }`}
                                        placeholder={
                                            errors.Weight && isDirty
                                                ? errors.Weight.message
                                                : "Weight in Kg"
                                        }
                                        autoComplete="off"
                                        {...register("Weight", {
                                            required: "Weight is required",
                                            min: {
                                                value: 20,
                                                message: "Please enter a valid weight in Kg",
                                            },
                                            max: {
                                                value: 350,
                                                message: "Please enter a valid weight in Kg",
                                            },
                                            validate: (v) => checkOnlyNumber(v, "Weight")
                                        })}
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <select
                                        className={`custom-select ${errors.BodyTypeId && isDirty
                                            ? "red-placeholder border border-danger"
                                            : ""
                                            }`}
                                        placeholder={
                                            errors.BodyTypeId && isDirty
                                                ? errors.BodyTypeId.message
                                                : "Body Type"
                                        }
                                        defaultValue={"0"}
                                        {...register("BodyTypeId", {
                                            required: "Please select Body Type",
                                            validate: (v) => checkIsSelected(v, "Body Type"),
                                        })}
                                        disabled={isSubmitting}
                                    >
                                        <option key={0} value={"0"}>
                                            Body Type
                                        </option>
                                        {bodyTypes.length > 0 &&
                                            bodyTypes.map((item) => (
                                                <option
                                                    key={item.BodyTypeId}
                                                    value={item.BodyTypeId}
                                                >
                                                    {item.BodyTypeDesc.toUpperCase()}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <select
                                        className={`custom-select ${errors.SkinColorId && isDirty
                                            ? "red-placeholder border border-danger"
                                            : ""
                                            }`}
                                        placeholder={
                                            errors.SkinColorId && isDirty
                                                ? errors.SkinColorId.message
                                                : "Complexion"
                                        }
                                        defaultValue={"0"}
                                        {...register("SkinColorId", {
                                            required: "Please select Complexion",
                                            validate: (v) => checkIsSelected(v, "Complexion"),
                                        })}
                                        disabled={isSubmitting}
                                    >
                                        <option key={0} value={"0"}>
                                            Complexion
                                        </option>
                                        {skinColor.length > 0 &&
                                            skinColor.map((item) => (
                                                <option
                                                    key={item.SkinColorId}
                                                    value={item.SkinColorId}
                                                >
                                                    {item.SkinColorDesc.toUpperCase()}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <select
                                        className={`custom-select ${errors.IsPhysicallyDisabled && isDirty
                                            ? "red-placeholder border border-danger"
                                            : ""
                                            }`}
                                        placeholder={
                                            errors.IsPhysicallyDisabled && isDirty
                                                ? errors.IsPhysicallyDisabled.message
                                                : "Physically Disabled"
                                        }
                                        defaultValue={"0"}
                                        {...register("IsPhysicallyDisabled", {
                                            required: "Please select Physically Disabled",
                                            validate: (v) => checkIsSelected(v, "Physically Disabled"),
                                        })}
                                        disabled={isSubmitting}
                                    >
                                        <option key={0} value={"0"}>
                                            Physically Disabled
                                        </option>
                                        <option key={1} value={"1"}>
                                            Yes
                                        </option>
                                        <option key={2} value={"2"}>
                                            No
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <select
                                        className={`custom-select ${errors.OccupationId && isDirty
                                            ? "red-placeholder border border-danger"
                                            : ""
                                            }`}
                                        placeholder={
                                            errors.OccupationId && isDirty
                                                ? errors.OccupationId.message
                                                : "OccupationId"
                                        }
                                        defaultValue={"0"}
                                        {...register("OccupationId", {
                                            required: "Please select Occupation",
                                            validate: (v) => checkIsSelected(v, "Occupation"),
                                        })}
                                        disabled={isSubmitting}
                                    >
                                        <option key={0} value={"0"}>
                                            Occupation
                                        </option>
                                        {occupation.length > 0 &&
                                            occupation.map((item) => (
                                                <option
                                                    key={item.OccupationId}
                                                    value={item.OccupationId}
                                                >
                                                    {item.OccupationDesc.toUpperCase()}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <select
                                        className={`custom-select ${errors.MotherToungeId && isDirty
                                            ? "red-placeholder border border-danger"
                                            : ""
                                            }`}
                                        placeholder={
                                            errors.MotherToungeId && isDirty
                                                ? errors.MotherToungeId.message
                                                : "Mother Tounge"
                                        }
                                        defaultValue={"0"}
                                        {...register("MotherToungeId", {
                                            required: "Please select Mother Tounge",
                                            validate: (v) => checkIsSelected(v, "Mother Tounge"),
                                        })}
                                        disabled={isSubmitting}
                                    >
                                        <option key={0} value={"0"}>
                                            Mother Tounge
                                        </option>
                                        {motherTounge.length > 0 &&
                                            motherTounge.map((item) => (
                                                <option
                                                    key={item.MotherTongueId}
                                                    value={item.MotherTongueId}
                                                >
                                                    {item.MotherTongueDesc.toUpperCase()}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 row pb-4">
                            {isDirty &&
                                errors &&
                                Object.values(errors).map((error, index) => (
                                    <div className="col-sm-12 col-lg-6" key={index}>
                                        <p className="text-danger text-left p-0 m-0">{`* ${error.message}`}</p>
                                    </div>
                                ))}
                        </div>
                        <ul className="list-inline text-right">
                            <li>
                                <button
                                    type="submit"
                                    className="btn green-btn"
                                >
                                    Submit
                                </button>
                            </li>
                        </ul>
                    </form>
                </div>
                <CenterModal
                    show={showModalPopUp}
                    onClose={closePopup}
                    heading={message.heading}
                    description={message.description}
                    size={"md"}
                />
            </ConditionalLoader>
        </CustomPopup>
    )
}

export default EditPartnerPreference