import React, { useEffect, useRef, useState } from "react";
import ConditionalLoader from "../../Components/ConditionalLoader";
import { getApiResponse } from "../../Utils/AxiosRequest";
import {
  API_PATHS,
  errorMessages,
  RESULTS_PER_PAGE_12,
} from "../../Utils/Constants/constants";
import MemberUserCard from "../../Components/Cards/MemberUserCard";
import SessionTimoutPopup from "../../Components/SessionTimoutPopup";
import CustomPagination from "../../Components/CustomPagination";
import { useOutletContext } from "react-router-dom";
import CenterModal from "../../Components/CenterModal";
import ViewProfilePopup from "../../Components/ViewProfilePopup";

const MemberSearch = () => {
  const [members, setMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [sessionTimeout, setSessionTimeout] = useState(false);
  const [currentPage, setcurrentPage] = useState(0);
  const [viewProfile, setViewProfile] = useState({});
  const totalPages = Math.ceil(members.length / RESULTS_PER_PAGE_12);
  const { updateToastMessage } = useOutletContext();

  const memberList = useRef([]);

  const sortedMembers = members.sort((a, b) => {
    if (sortOrder === "0") {
      return 0;
    } else if (sortOrder === "1") {
      return a.FirstName.localeCompare(b.FirstName);
    } else {
      return b.FirstName.localeCompare(a.FirstName);
    }
  });
  const noSearchResult = members.length === 0 && isSearchClicked;
  const closePopup = () => {
    setShowModal(false);
    setMessage({
      heading: "",
      description: "",
    });
  };

  const handleSOrtChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleQueryChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value === "") {
      setMembers(memberList.current);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (searchQuery.trim) {
      const response = await getApiResponse(
        API_PATHS.searchMember + `?searchTerm=${searchQuery}`,
        true
      );
      if (response.status === 200) {
        setMembers(response.data ?? []);
        setcurrentPage(0);
      } else if (response.status === 401) {
        setSessionTimeout(true);
      } else {
        updateToastMessage(errorMessages.error, "danger");
      }
    }
    setIsSearchClicked(true);
    setIsLoading(false);
  };

  const handleViewProfile = (value) => {
    setViewProfile({ show: true, ...value })
  }

  const closeViewProfile = () => {
    setViewProfile(prvs => ({
      ...prvs,
      show: false,
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await getApiResponse(
        API_PATHS.searchMember + `?searchTerm=${""}`,
        true
      );
      if (response.status === 200) {
        setMembers(response.data ?? []);
        memberList.current = response.data ?? [];
        setcurrentPage(0);
      } else if (response.status === 401) {
        setSessionTimeout(true);
      } else {
        updateToastMessage(errorMessages.error, "danger");

      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <SessionTimoutPopup isSessionTimout={sessionTimeout}>
      <div className="update-profile-body">
        <h2 className="main-title mt-3 mb-3">Search Member</h2>
        <section className="search-member-body">
          <form className="common-form">
            <div className="row">
              <div className="col-md-8">
                <div
                  className="input-group searchbar-row"
                  style={{
                    zIndex: 0,
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="searchTxt"
                    placeholder="Search"
                    onChange={handleQueryChange}
                    autoComplete="off"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn"
                      type="submit"
                      disabled={isLoading}
                      onClick={handleSearch}
                    >
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <select className="custom-select" onChange={handleSOrtChange}>
                    <option value="0">Sort</option>
                    <option value="1">Sort by A-Z</option>
                    <option value="-1">Sort by Z-A</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
          <div>
            <ConditionalLoader isLoading={isLoading}>
              <div className="matrimonial-container">
                <div className="row">
                  {sortedMembers.length > 0
                    ? sortedMembers
                      .slice(
                        (currentPage) * RESULTS_PER_PAGE_12,
                        (currentPage + 1) * RESULTS_PER_PAGE_12
                      )
                      .map((item) => (
                        <MemberUserCard
                          key={item.MemberId}
                          id={item?.MemberId}
                          name={item?.FirstName ?? "" + item?.LastName ?? ""}
                          gender={item?.Gender ?? ''}
                          city={item?.CityName ?? ''}
                          imgUrl={item?.DisplayURL}
                          viewMore={(e) => {
                            e.preventDefault()
                            handleViewProfile(item)
                          }}
                        />
                      ))
                    : !isSearchClicked && (
                      <p className="p-4 text-center">No Member Found !!</p>
                    )}
                </div>
              </div>
            </ConditionalLoader>
            {noSearchResult ? (
              <p className="p-4 text-center">
                <b>No Search result found !!</b>
              </p>
            ) : (
              <div className="d-flex justify-content-center pagination">
                {totalPages > 0 && (
                  <CustomPagination pageNumber={currentPage} setpageNumber={setcurrentPage} numberOfPages={totalPages} />
                )}
              </div>
            )}
          </div>
        </section>
        <CenterModal
          show={showModal}
          onClose={closePopup}
          heading={message.heading}
          description={message.description}
        />
        <ViewProfilePopup
          show={viewProfile.show}
          onClose={closeViewProfile}
          details={viewProfile}
        />
      </div>
    </SessionTimoutPopup>
  );
};

export default MemberSearch;
