import React, { useContext } from "react";
import icon8 from "../Assets/img/icon-8.png";
import icon9 from "../Assets/img/icon-9.png";
import icon10 from "../Assets/img/icon-10.png";
import logoW from "../Assets/img/logo-w.png";
import news1 from "../Assets/img/news-1.jpg";
import news2 from "../Assets/img/news-1.jpg";
import { ApiDataContext } from "../Contexts/ApiData";
import { useNavigateToNews } from "../Hooks/Navigation";
import { Spinner } from "react-bootstrap";
import { getDateTime, getLimitHtmlContent } from "../Utils/format";
import { URL_PATH } from "../Utils/Constants/constants";
import { Link } from "react-router-dom";

const Footer = () => {
  const apiData = useContext(ApiDataContext);

  const fullAddress = apiData?.layout?.Address ?? "";
  const contactInfo = apiData?.layout?.Mobilenumber ?? "";
  const emailAddress = apiData?.layout?.Email ?? "";
  const news = apiData?.layout?.News ?? [];
  const aboutUs = apiData?.layout?.AboutUs ?? "";
  const isHeaderLoading = apiData?.isHeaderLoading;

  const navigateToNews = useNavigateToNews();

  return (
    <footer>
      <div className="footer-1">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 pl-0 pr-0">
                <div className="footer-top-col">
                  <img src={icon8} alt="" />
                  {isHeaderLoading ? <Spinner size="sm" /> : <p>
                    Phone Number :{" "}
                    <a href={`tel: ${contactInfo}`}>
                      {contactInfo}
                    </a>
                  </p>}
                </div>
              </div>
              <div className="col-sm-4 pl-0 pr-0">
                <div className="footer-top-col">
                  <img src={icon9} alt="" />
                  {isHeaderLoading ? <Spinner size="sm" /> : <p>
                    Email :{" "}
                    <a href={`mailto:${emailAddress}`}>
                      {emailAddress}
                    </a>
                  </p>}
                </div>
              </div>
              <div className="col-sm-4 pl-0 pr-0">
                <div className="footer-top-col">
                  <img src={icon10} alt="" />
                  {isHeaderLoading ? <Spinner size="sm" /> : <p>
                    Address :{" "}
                    {fullAddress}
                  </p>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-body">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="footer-col">
                  <h2 className="sub-title text-white">ABOUT AIMC</h2>
                  <img src={logoW} alt="" className="w-100 mb-4" />
                  {isHeaderLoading ? (
                    <Spinner />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getLimitHtmlContent(aboutUs, 120),
                      }}
                    ></div>
                  )}
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="footer-col">
                  <h2 className="sub-title text-white">OUR RECENT NEWS</h2>
                  {isHeaderLoading ? (
                    <Spinner />
                  ) : (
                    news?.map((item) => (
                      <div className="recent-news-row" key={item.NewsId}>
                        <div className="recent-news-img">
                          <img src={item.DisplayURL} alt="latest-news-image" />
                        </div>
                        <div className="recent-news-content">
                          <h3>
                            <a
                              href="#"
                              onClick={() => {
                                navigateToNews(item?.NewsId);
                              }}
                            >
                              {item.NewsTitle}
                            </a>
                          </h3>
                          <span>{getDateTime(item?.ScheduleDate, "")}</span>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-col">
                  <h2 className="sub-title text-white">OUR History</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getLimitHtmlContent(aboutUs, 230),
                    }}
                  ></div>
                  {/* <p>
                    AIMC is a nonproﬁt organization supported by community
                    leaders
                  </p> */}
                  {/* <input
                    type="email"
                    placeholder="Enter Email"
                    className="form-control"
                  />
                  <div className="mt-3">
                    <a href="#" className="btn green-btn">
                      Send Message <i className="fas fa-paper-plane"></i>
                    </a>
                  </div> */}
                </div>
                {/* <div className="footer-col">
                  <h2 className="sub-title text-white">OUR NEWSLETTER</h2>
                  <p>
                    AIMC is a nonproﬁt organization supported by community
                    leaders
                  </p>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    className="form-control"
                  />
                  <div className="mt-3">
                    <a href="#" className="btn green-btn">
                      Send Message <i className="fas fa-paper-plane"></i>
                    </a>
                  </div>
                </div> */}
                <div className="footer-social-icon">
                  <ul className="list-unstyled">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-2">
        <div className="container">
          <p className="mb-0 text-center">
            ©2021 <span className="green-text">AIMC</span> - All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
