import React, { useState } from 'react'
import CustomPopup from './CustomPopup'
import { Modal, Spinner } from "react-bootstrap";


const ConfirmationPopup = ({
    showModal,
    closeModal,
    heading,
    confirmCallback,
    denyCallback
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const congirmHandler = async (e) => {
        e.preventDefault();
        if (isLoading)
            return
        setIsLoading(true);
        await confirmCallback();
        setIsLoading(false);
    }
    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <div className='w-100 d-flex justify-content-center'>

                    <h3 className='h3 text-center'>{heading}</h3>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-center'>
                    <button className='btn btn-md btn-success mr-5 px-4'
                        onClick={(e) => {
                            e.preventDefault();
                            denyCallback();
                        }}
                    >No</button>
                    <button className='btn btn-md btn-danger ml-5 px-4'
                        onClick={congirmHandler}
                    >{isLoading ? <Spinner size="sm" /> : "Yes"}</button>
                </div>
            </Modal.Body>
        </Modal>

    )
}

export default ConfirmationPopup