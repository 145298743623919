import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { errorMessages, URL_PATH } from '../Utils/Constants/constants';
import ConditionalLoader from './ConditionalLoader';
import Modal from './Modal';
import { LoginContext } from '../Contexts/LoginContext';
import CenterModal from './CenterModal';
import { ApiDataContext } from '../Contexts/ApiData';

const SessionTimoutPopup = ({ isSessionTimout, children }) => {
    const { setIsLoggedIn } = useContext(LoginContext);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { setUserName } = useContext(ApiDataContext);

    const closePopup = () => {
        setIsLoading(true);
        if (isSessionTimout) {
            setUserName('');
            setIsLoggedIn(false);
            navigate(URL_PATH.login);
        }
    }
    return (
        <ConditionalLoader isLoading={isLoading}>
            {children}
            <CenterModal
                show={isSessionTimout}
                onClose={closePopup}
                isLoading={isLoading}
                heading={errorMessages.sessionTimeout}
                description={errorMessages.sessionTimeoutDescription}
                buttonText={"Login"}
            />
        </ConditionalLoader>
    )
}

export default SessionTimoutPopup