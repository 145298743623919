import React, { useEffect, useState } from "react";
import JobCard from "../../Components/Cards/JobCard";
import { getApiResponse } from "../../Utils/AxiosRequest";
import { API_PATHS } from "../../Utils/Constants/constants";
import ConditionalLoader from "../../Components/ConditionalLoader";
import CustomPagination from "../../Components/CustomPagination";

const Jobs = () => {
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [searchQuery, setSearchQuery] = useState('')
  const [pageNumber, setpageNumber] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [loadError, setLoadError] = useState('');

  const jobList = apiData?.Jobs ?? [];
  const pageBanner = apiData.PageBanner?.length ? apiData?.PageBanner[0]?.DisplayURL ?? '' : '';
  const pageTitle = apiData.PageBanner?.length ? apiData?.PageBanner[0]?.PageBannerTitle ?? 'Jobs & Vacancy' : '';

  const recordPerPage = 50;
  const startIndex = pageNumber * recordPerPage;
  const numberOfPages = Math.ceil(jobList.length / recordPerPage);
  const sortedData = jobList.sort((a, b) => {
    if (sortOrder === "0") {
      return 0;
    }
    return sortOrder === "1" ? new Date(a.CreatedOn).getTime() - new Date(b.CreatedOn).getTime() : new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime();
  })

  const fetchData = async (value) => {
    setIsLoading(true);
    setLoadError('');
    const response = await getApiResponse(API_PATHS.jobs + `?searchTerm=${value ?? searchQuery.trim()}`);
    if (response.status === 200) {
      setApiData(response.data);
    } else {
      setLoadError(response.message);
    }
    setIsLoadingPage(false)
    setIsLoading(false);
  }

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  }
  const handleQueryChange = (e) => {
    const value = e.target.value.trim();
    setSearchQuery(value);
    if (value === "") {
      fetchData(value);
    }
  }

  const handleSearch = (e) => {
    e.preventDefault();
    fetchData();
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pageNumber])

  return (
    <ConditionalLoader isLoading={isLoadingPage} error={loadError} refresh={fetchData}>
      <section className="inner-banner">
        <div className="inner-banner-img">
          <img src={pageBanner} alt="" />
        </div>
        <div className="banner-overlay">
          <div className="container">
            <h2>Job & Vacancy</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {pageTitle}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="job-search-body">
        <div className="container">
          <h2 className="main-title text-center">
            Explore Thousand Of Jobs With Just Simple Search...
          </h2>
          <form className="common-form">
            <div className="row">
              <div className="col-md-12 job-search">
                <div
                  className="input-group searchbar-row"
                  style={{
                    zIndex: 0,
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="searchTxt"
                    placeholder="Search"
                    onChange={handleQueryChange}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn"
                      type="submit"
                      disabled={isLoading}
                      onClick={handleSearch}
                    >
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <ConditionalLoader isLoading={isLoading}>
            <div className="row align-items-center">
              <div className="col-md-6">
                <h3 className="sub-title">{jobList.length} Jobs & Vacancies</h3>
              </div>
              <div className="col-md-6">
                <div className="job-filter common-form">
                  <span>Sort by:</span>
                  <select name="cars" className="custom-select" onChange={handleSortChange}>
                    <option value="0">Sort By</option>
                    <option value="1">Most Recent</option>
                    <option value="-1">Oldest first</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="job-list">
              <div className="row">
                {sortedData
                  .slice(startIndex, startIndex + recordPerPage)
                  .map((job, index) => (
                    <JobCard
                      key={index}
                      location={job.JobLocation}
                      companyName={job.CompanyName}
                      imgUrl={job.imgUrl}
                      position={job.JobDesignation}
                      link={job.JobLink}
                    />
                  ))}
              </div>
              {jobList.length > 0 ?
                <CustomPagination pageNumber={pageNumber} setpageNumber={setpageNumber} numberOfPages={numberOfPages} /> : <div>
                  <p className="text-center font-weight-bold h4">No Job found</p>
                </div>
              }
            </div>
          </ConditionalLoader>
        </div>
      </section>
    </ConditionalLoader>
  );
};

export default Jobs;
