import React, { useContext, useEffect, useState } from "react";
import ButtonSlider from "../../Components/Carousel/ButtonSlider";
import SquareImageSlider from "../../Components/Carousel/SquareImageSlider";
import NewsCard from "../../Components/Cards/NewsCard";
import UserCard from "../../Components/Cards/UserCard";
import { Link } from "react-router-dom";
import { ApiDataContext } from "../../Contexts/ApiData";
import Modal from "../../Components/Modal";
import Banner from "../../Components/Banner";

const History = () => {
  const [showAwardModel, setshowAwardModel] = useState(false);
  const [awardModelContent, setawardModelContent] = useState({});

  const apiData = useContext(ApiDataContext);

  const pageBanner = apiData?.PageBanner;
  const historyContent = apiData?.CMSPage?.HistoryContent;
  const historySlider = apiData?.Display_HistorySlider;

  const stateLeaders = apiData?.StateLeaders?.sort((a, b) => (a.SortOrder - b.SortOrder));
  const allIndiaLeaders = apiData?.AllIndiaLeaders?.sort((a, b) => (a.SortOrder - b.SortOrder));
  const coreLeaders = apiData?.CoreLeaders?.sort((a, b) => (a.SortOrder - b.SortOrder));
  const eminentLeaders = apiData?.EminentLeaders?.sort((a, b) => (a.SortOrder - b.SortOrder));
  const awards = apiData?.Awards?.sort((a, b) => (a.SortOrder - b.SortOrder));

  return (
    <>
      {pageBanner && pageBanner.length ? (
        <Banner
          heading={pageBanner[0].PageBannerTitle}
          imgUrl={pageBanner[0].DisplayURL}
          alt={"Our Hostory"}
        />
      ) : (
        <></>
      )}
      <section className="aimc-history">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="float-className">
                <div className="about-img">
                  <div className="about-img-row">
                    <SquareImageSlider images={historySlider} />
                  </div>
                </div>
              </div>
              {/* <h2 className="main-title mb-3">
                As Momin Conference Also Known As Jamaat-Ul-Ansar
              </h2>
              <h3 className="sub-title mb-3">
                That Was Founded In India In 1911
              </h3> */}
              <div
                className="html_render"
                dangerouslySetInnerHTML={{ __html: historyContent }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      {awards && (
        <section className="awards">
          <div className="container">
            <h2 className="main-title mb-3">Awards and Recognition</h2>
            <div className="awards-row">
              <ButtonSlider>
                <>
                  {awards?.map((award, index) => (
                    <div className="awards-col" key={award?.AwardId ?? index}>
                      <NewsCard
                        imgUrl={award?.DisplayURL}
                        date={award?.AwardYear}
                        heading={award?.AwardName}
                        summary={award?.AwardContent}
                        subContent={award?.AwardBy}
                        location={award?.AwardLocation}
                        dFormat={"MMMM"}
                        readmore={() => {
                          setawardModelContent(award);
                          setshowAwardModel(true);
                        }}
                      />
                    </div>
                  ))}
                </>
              </ButtonSlider>
            </div>
          </div>
          <Modal
            showModal={showAwardModel}
            closeModal={setshowAwardModel}
            heading={awardModelContent?.AwardName}
            imgUrl={awardModelContent?.DisplayURL}
            summary={awardModelContent?.AwardContent}
          />
        </section>
      )}

      <section className="board-management">
        <div className="container">
          {eminentLeaders && eminentLeaders.length ? (
            <div>
              <div className="monin-service-title">
                <h2 className="sub-title text-center mb-3">Eminent Leaders</h2>
                <h3 className="main-title text-center mb-5">
                  Our Management Whose Knowledge Is Useful For Others
                </h3>
              </div>
              <div className="row justify-content-center">
                {eminentLeaders?.map((leader) => (
                  <UserCard
                    key={leader?.LeaderId}
                    name={leader?.LeaderName}
                    summary={leader.LeaderDesignation}
                    duration={leader?.Duration}
                    imgUrl={leader?.DisplayURL}
                  />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
          {coreLeaders && coreLeaders.length ? (
            <div className="board-leaders">
              <h2 className="sub-title text-center mb-3">Core Commitee</h2>
              <div className="row justify-content-center">
                {coreLeaders?.map((leader) => (
                  <UserCard
                    key={leader?.LeaderId}
                    name={leader?.LeaderName}
                    summary={leader.LeaderDesignation}
                    duration={leader?.Duration}
                    imgUrl={leader?.DisplayURL}
                  />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
          {allIndiaLeaders && allIndiaLeaders.length ? (
            <div className="board-leaders">
              <h2 className="sub-title text-center mb-3">
                All India Working Committee
              </h2>
              <div className="row justify-content-center">
                {allIndiaLeaders?.map((leader) => (
                  <UserCard
                    key={leader?.LeaderId}
                    name={leader?.LeaderName}
                    summary={leader.LeaderDesignation}
                    duration={leader?.Duration}
                    imgUrl={leader?.DisplayURL}
                  />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
          {stateLeaders && stateLeaders.length ? (
            <div className="board-leaders">
              <h2 className="sub-title text-center mb-3">
                State Presidents of Aimc
              </h2>
              <div className="row justify-content-center">
                {stateLeaders?.map((leader) => (
                  <UserCard
                    key={leader?.LeaderId}
                    name={leader?.LeaderName}
                    summary={leader.LeaderDesignation}
                    duration={leader?.Duration}
                    imgUrl={leader?.DisplayURL}
                  />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>
    </>
  );
};

export default History;
