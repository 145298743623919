import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  API_PATHS,
  errorMessages,
  URL_PATH,
} from "../Utils/Constants/constants";
import { LoginContext } from "../Contexts/LoginContext";
import { clearSession } from "../Utils/Auth/session";
import { getApiResponse } from "../Utils/AxiosRequest";
import { MemberDetails } from "../Contexts/MemberDetailContext";
import Modal from "../Components/Modal";
import { Accordion, Card, Nav, Spinner, ToastContainer } from "react-bootstrap";
import ImageSubstitute from "../Components/ImageSubstitute";
import ConfirmationPopup from "../Components/ConfirmationPopup";
import SessionTimoutPopup from "../Components/SessionTimoutPopup";
import CustomToast from "../Components/CustomToast";
import { ApiDataContext } from "../Contexts/ApiData";
import CenterModal from "../Components/CenterModal";
import ErrorBoundaty from "../Components/ErrorBoundaty"

const MembershipLayout = () => {
  const { setUserName } = useContext(ApiDataContext);
  const [showNav, setshowNav] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const { pathname } = useLocation();
  const { setIsLoggedIn } = useContext(LoginContext);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const [memberData, setMemberData] = useState({});
  const [educationData, setEducationData] = useState([]);
  const [experienceData, setExperienceData] = useState([]);
  const [sessionTimeout, setSessionTimeout] = useState(false);
  const [matProfileCount, setMatProfileCount] = useState(0);
  const [jobCount, setJobCount] = useState(0);
  const [activeKey, setActiveKey] = useState("0");
  const [toastConfig, setToastConfig] = useState({
    show: false,
    message: '',
    type: 'success'
  });
  const [isExiting, setIsExiting] = useState(false);
  const timeoutId = useRef(null);
  const navigate = useNavigate();

  const firstName = memberData?.FirstName ?? "";
  const lastName = memberData?.LastName ?? "";

  let page = "Home";
  switch (pathname) {
    case URL_PATH?.memberhome:
      page = "Home";
      break;
    case URL_PATH?.memberprofile:
      page = "View Profile";
      break;
    case URL_PATH?.memberprofileedit:
      page = "Update Profile";
      break;
    case URL_PATH?.matrimonialdahboard:
      page = "Matrimonial Dashboard";
      break;
    case URL_PATH?.matrimonialprofiles:
      page = "Matrimonial Profiles";
      break;
    case URL_PATH?.createprofile:
      page = "Create Profile";
      break;
    case URL_PATH?.memberjobdashboard:
      page = "View Jobs";
      break;
    case URL_PATH?.memberaddjob:
      page = "Add Jobs";
      break;
  }

  const toggleNav = () => {
    setshowNav((prvs) => !prvs);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    setUserName('');
    setShowConfirmLogout(true)
  };
  const confirmLogout = () => {
    setShowConfirmLogout(false);
    setIsLoading(true);
    clearSession();
    setIsLoggedIn(false);
    navigate(URL_PATH.login);
  }
  const cancelLogout = () => {
    setShowConfirmLogout(false);
  }
  const onCloseModal = () => {
    clearSession();
    setIsLoggedIn(false);
    navigate(URL_PATH.login);
  };
  const closeToast = () => {
    setIsExiting(true);
    setTimeout(() => {
      setIsExiting(false);
      setToastConfig({
        show: false,
        message: '',
        type: "success"
      })
    }, 800);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
  }
  const updateToastMessage = (message, type) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    setToastConfig({
      show: true,
      message,
      type
    })
    timeoutId.current = setTimeout(closeToast, 1000);
  }

  const fetchData = async () => {
    setError("");
    let apiPath = API_PATHS.viewMember;
    try {
      setIsLoading(true);
      const response = await getApiResponse(apiPath, true);
      if (response.status == 200) {
        setMemberData(response?.data?.MemberDetails ?? {});
        setEducationData(response?.data?.MemberEducation ?? []);
        setExperienceData(response?.data?.MemberWorkExperience ?? []);
        setUserName(response?.data?.MemberDetails?.FirstName ?? "");
        setMatProfileCount(response?.data?.matrimonialprofilecount)
        setJobCount(response?.data?.jobcount)
      } else if (response.status === 401) {
        setSessionTimeout(true);
      } else {
        setError(errorMessages.error);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setMessage({
        heading: errorMessages.error,
        description: errorMessages.sessionTimeoutDescription,
      });
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (!memberData.MemberId || isUpdated) {
      fetchData();
      setIsUpdated(false);
    }
  }, [isUpdated]);

  return (
    <SessionTimoutPopup isSessionTimout={sessionTimeout}>
      <section id="wrapper" className={showNav ? "active" : ""}>
        <div className="w-100 position-relative">
          <ToastContainer position="top-end" className=" position-fixed pt-2" style={{
            left: "100%",
            bottom: "50px",
            zIndex: 1500
          }}>
            <CustomToast
              show={toastConfig.show}
              message={toastConfig.message}
              type={toastConfig.type}
              onClose={closeToast}
              isExiting={isExiting}
            />
          </ToastContainer>
        </div>
        <aside className="side-menu">
          <div className="side-menu-inner">
            <div className="container">
              <div className="close-btn-1">
                <button
                  id="btn-close-1 focus-ring"
                  className="btn"
                  onClick={toggleNav}
                >
                  <i className="fas fa-times-circle"></i>
                </button>
              </div>
              <div className="user-id">
                <div className="user-img">
                  {isLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <>
                      {memberData?.DisplayURL && memberData.DisplayURL !== `${process.env.REACT_APP_API_URL}/` ? <img src={memberData?.DisplayURL} alt="" /> : <ImageSubstitute name={firstName} sm />}
                    </>
                  )}
                </div>
                <div className="user-title">
                  <span>Hello,</span>
                  {isLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <h2>
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {firstName}
                      </span>
                      <span> </span>
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {lastName}
                      </span>
                    </h2>
                  )}
                </div>
              </div>

              <div>
                <Accordion
                  activeKey={activeKey}
                  defaultActiveKey="0"
                  onSelect={(selectedKey) => {
                    setActiveKey(selectedKey);
                  }}
                  id="side-menu"
                >
                  {/* Account Section */}
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      as={Card.Header}
                      className="accordion-header"
                    >
                      <div className="d-flex justify-content-between w-100">
                        <span>
                          <i className="fas fa-user"></i> Account
                        </span>
                        <span className="accordion-toggle-icon">
                          <i className={`fas ${activeKey === "0" ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Card>
                        <Card.Body>
                          <Nav as="ul" className="d-flex flex-column">
                            <Nav.Item as="li" className={pathname === URL_PATH.memberhome ? "active" : ""}>
                              <Link to={URL_PATH.memberhome}>Home</Link>
                            </Nav.Item>
                            <Nav.Item as="li" className={pathname === URL_PATH.memberprofile ? "active" : ""}>
                              <Link to={URL_PATH.memberprofile}>View Profile</Link>
                            </Nav.Item>
                            <Nav.Item as="li" className={pathname === URL_PATH.memberprofileedit ? "active" : ""}>
                              <Link to={URL_PATH.memberprofileedit}>Update Profile</Link>
                            </Nav.Item>
                            <Nav.Item as="li" className={pathname === URL_PATH.searchmember ? "active" : ""}>
                              <Link to={URL_PATH.searchmember}>Search Member</Link>
                            </Nav.Item>
                          </Nav>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Matrimonial Section */}
                  <Accordion.Item eventKey="1">
                    <Accordion.Header
                      as={Card.Header}
                      className="accordion-header"
                    >
                      <div className="d-flex justify-content-between w-100">
                        <span>
                          <i className="fas fa-heart"></i> Matrimonial
                        </span>
                        <span className="accordion-toggle-icon">
                          <i className={`fas ${activeKey === "1" ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Card>
                        <Card.Body>
                          <Nav as="ul" className="d-flex flex-column">
                            <Nav.Item as="li" className={pathname === URL_PATH.matrimonialdahboard ? "active" : ""}>
                              <Link to={URL_PATH.matrimonialdahboard}>Dashboard</Link>
                            </Nav.Item>
                            <Nav.Item as="li" className={pathname === URL_PATH.matrimonialprofiles ? "active" : ""}>
                              <Link to={URL_PATH.matrimonialprofiles}>Profiles</Link>
                            </Nav.Item>
                            <Nav.Item as="li" className={pathname === URL_PATH.createprofile ? "active" : ""}>
                              <Link to={URL_PATH.createprofile}>Create Profile</Link>
                            </Nav.Item>
                          </Nav>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Job & Vacancy Section */}
                  <Accordion.Item eventKey="2">
                    <Accordion.Header
                      as={Card.Header}
                      className="accordion-header"
                    >
                      <div className="d-flex justify-content-between w-100">
                        <span>
                          <i className="fas fa-briefcase"></i> Job & Vacancy
                        </span>
                        <span className="accordion-toggle-icon">
                          <i className={`fas ${activeKey === "2" ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Card>
                        <Card.Body>
                          <Nav as="ul" className="d-flex flex-column">
                            <Nav.Item as="li" className={pathname === URL_PATH.memberjobdashboard ? "active" : ""}>
                              <Link to={URL_PATH.memberjobdashboard}>View Jobs</Link>
                            </Nav.Item>
                            <Nav.Item as="li" className={pathname === URL_PATH.memberaddjob ? "active" : ""}>
                              <Link to={URL_PATH.memberaddjob}>Add Jobs</Link>
                            </Nav.Item>
                          </Nav>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                {/* Logout Section */}
                <div className="log-out">
                  <a href="#" onClick={handleLogout}>
                    <i className="fas fa-sign-out-alt"></i> Log Out
                  </a>
                </div>
              </div>

            </div>
          </div>
        </aside>
        <div id="content">
          <div className="container">
            <div className="breadcrumb-body">
              <button className="btn bar-btn" onClick={toggleNav}>
                <i className="fas fa-bars"></i>
              </button>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Account</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {page}
                  </li>
                </ol>
              </nav>
            </div>
            <MemberDetails.Provider
              value={{
                memberDetails: memberData,
                education: educationData,
                workExperience: experienceData,
                jobCount,
                matProfileCount,
                setMatProfileCount,
                setJobCount,
                setMemberDetails: setMemberData,
                setEducation: setEducationData,
                setWorkExperience: setExperienceData,
                setIsUpdated,
                isLoading,
                error,
              }}
            >
              <ErrorBoundaty>
                <Outlet context={{ updateToastMessage }} />
              </ErrorBoundaty>
            </MemberDetails.Provider>
          </div>
        </div>
        <CenterModal
          show={showModal}
          onClose={onCloseModal}
          heading={message.heading}
          description={message.description}
        />
        <ConfirmationPopup showModal={showConfirmLogout} closeModal={cancelLogout} denyCallback={cancelLogout} heading={"Logout"} confirmCallback={confirmLogout} />
      </section>
    </SessionTimoutPopup>
  );
};

export default MembershipLayout;
