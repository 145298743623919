import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { phoneValidate } from "../../Utils/validation/validators";
import ErrorsList from "../../Components/ErrorsList";
import { MemberDetails } from "../../Contexts/MemberDetailContext";
import ConditionalLoader from "../../Components/ConditionalLoader";
import { getApiResponse, postData } from "../../Utils/AxiosRequest";
import {
  API_PATHS,
  errorMessages,
  URL_PATH,
} from "../../Utils/Constants/constants";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import SessionTimoutPopup from "../../Components/SessionTimoutPopup";
import CenterModal from "../../Components/CenterModal";

const MemberAddJob = ({ edit }) => {
  const { memberDetails, isLoading } = useContext(MemberDetails);
  const [apiData, setApiData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const [sessionTimeout, setSessionTimeout] = useState(false);
  const navigate = useNavigate()
  const { updateToastMessage } = useOutletContext()
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setValue
  } = useForm();

  const closePopup = () => {
    setShowModal(false);
    setMessage({
      heading: "",
      description: "",
    });
  };

  const addJob = async (data) => {
    // handle add job
    setIsSubmitting(true);
    const reqBody = { ...data };
    reqBody.MemberId = memberDetails.MemberId;
    reqBody.CreatedBy = memberDetails.MemberId;
    reqBody.IsActive = edit ? reqBody.IsActive : true;
    reqBody.ModifiedBy = edit ? memberDetails.MemberId : undefined;
    let response
    if (edit) {
      response = await postData(API_PATHS.updateJob, reqBody, {
        bearer: true,
      });
    } else {
      response = await postData(API_PATHS.createJob, reqBody, {
        bearer: true,
      });
    }
    switch (response.status) {
      case 200:
        if (response.data.IsSuccess) {
          updateToastMessage(`Job ${edit ? "Updated" : "Created"} Successfully`, "success");
          navigate(URL_PATH.memberjobdashboard);
        } else {
          updateToastMessage(errorMessages.error, "danger")
        }
        break;
      case 400:
        updateToastMessage(errorMessages.verifyInput, "danger")
        break;
      case 401:
        setSessionTimeout(true);
        break;
      default:
        updateToastMessage(errorMessages.error, "danger")
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (edit && id) {

      const mapDataFromAPI = async (data) => {
        Object.keys(data).forEach((key) => {
          if (data[key] === "00000000-0000-0000-0000-000000000000") {
            setValue(key, "0");
          } else if (key === "IsPhysicallyDisabled") {
            setValue(key, data[key] ? "1" : "2");
          } else {
            setValue(key, data[key] ?? "");
          }
        });
      }
      const fetchData = async () => {
        setIsLoadingData(true);
        const response = await getApiResponse(API_PATHS.viewJob + `?jobId=${id}`, true)
        if (response.status === 200) {
          setApiData(response.data)
          await mapDataFromAPI(response.data)
        } else if (response.status === 401) {
          setSessionTimeout(true);
        } else {
          setShowModal(true);
          setMessage({
            heading: errorMessages.error,
            description: errorMessages.description
          })
        }
        setIsLoadingData(false);
      }
      fetchData()
    }
  }, [id])

  return (
    <ConditionalLoader isLoading={isLoading}>
      <SessionTimoutPopup isSessionTimout={sessionTimeout}>
        <ConditionalLoader isLoading={isLoadingData}>
          <div className="add-job-body">
            <div className="common-form">
              <h2 className="main-title mt-3 mb-3">Add New Job</h2>
              <form onSubmit={handleSubmit(addJob)}>
                <div id="job-form-container">
                  <div className="row add-job-row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          className={`form-control ${errors.JobName && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                            }`}
                          placeholder={
                            errors.JobName && isDirty
                              ? errors.JobName.message
                              : "Job Name"
                          }
                          autoComplete="off"
                          {...register("JobName", {
                            required: "Please enter JobName",
                          })}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          className={`form-control ${errors.JobDesignation && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                            }`}
                          placeholder={
                            errors.JobDesignation && isDirty
                              ? errors.JobDesignation.message
                              : "Designation"
                          }
                          autoComplete="off"
                          {...register("JobDesignation", {
                            required: "Please enter Designation",
                          })}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          className={`form-control ${errors.CompanyName && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                            }`}
                          placeholder={
                            errors.CompanyName && isDirty
                              ? errors.CompanyName.message
                              : "Company Name"
                          }
                          autoComplete="off"
                          {...register("CompanyName", {
                            required: "Please enter Company Name",
                          })}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          className={`form-control ${errors.ContactInfo && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                            }`}
                          placeholder={
                            errors.ContactInfo && isDirty
                              ? errors.ContactInfo.message
                              : "Phone No."
                          }
                          {...register("ContactInfo", {
                            required: "Please enter your phone number",
                            maxLength: {
                              value: 10,
                              message: "Please enter a valid mobile number",
                            },
                            minLength: {
                              value: 10,
                              message: "Please enter a valid mobile number",
                            },
                            validate: phoneValidate,
                          })}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="url"
                          className={`form-control ${errors.JobLink && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                            }`}
                          placeholder={
                            errors.JobLink && isDirty
                              ? errors.JobLink.message
                              : "Link"
                          }
                          autoComplete="off"
                          {...register("JobLink", {
                            required: "Please enter Link",
                          })}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          className={`form-control ${errors.JobLocation && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                            }`}
                          placeholder={
                            errors.JobLocation && isDirty
                              ? errors.JobLocation.message
                              : "Job Location"
                          }
                          autoComplete="off"
                          {...register("JobLocation", {
                            required: "Please enter Location",
                          })}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          className={`form-control ${errors.JobValidTill && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                            }`}
                          placeholder={
                            errors.JobValidTill && isDirty ? errors.JobValidTill.message : "Job Valid Till"
                          }
                          {...register("JobValidTill", {
                            min: {
                              value: new Date().toLocaleDateString(),
                              message: "Please select a valid Date",
                            },
                            required: "Please add your Date of Birth",
                          })}
                          onFocus={(e) => {
                            e.target.type = "date";
                          }}
                          onBlur={(e) => {
                            e.target.type = "text";
                          }}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <textarea
                          type="text"
                          className={`form-control ${errors.JobDescription && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                            }`}
                          placeholder={
                            errors.JobDescription && isDirty
                              ? errors.JobDescription.message
                              : "Job Description"
                          }
                          autoComplete="off"
                          {...register("JobDescription", {
                            required: "Please enter Description",
                          })}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                </div>
                <ErrorsList errors={errors} isDirty={isDirty} />
                <div>
                  {isSubmitting ? (
                    <button className="btn green-btn mt-4 px-4">
                      <Spinner size="sm" />
                    </button>
                  ) : (
                    <input
                      type="submit"
                      value="Submit"
                      className="btn green-btn mt-4"
                    />
                  )}
                </div>
              </form>
            </div>
            <CenterModal
              show={showModal}
              onClose={closePopup}
              heading={message.heading}
              description={message.description}
              buttonText={"Ok"}
            />
          </div>
        </ConditionalLoader>
      </SessionTimoutPopup>
    </ConditionalLoader>
  );
};

export default MemberAddJob;
