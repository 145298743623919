import React from "react";

const JobCard = ({ imgUrl, position, companyName, link }) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
      <div className="card text-center border-none team-item-1">
        <div className="job-body">
          {/* <div className="job-img text-center">
            <img src={imgUrl} alt="" />
          </div> */}
          <h3 className="sub-title-2">{position}</h3>
          <span>{companyName}</span>
          <div className="job-apply-row">
            <div className="job-loction">Delhi</div>
            {link && <a href={link} className={`btn boreder-btn ${link ? "" : "disabled"}`} target="_blank">
              View More
            </a>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
