import React from "react";

const DonationForm = () => {
  return (
    <form>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Donor Name
											"
              required=""
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Phone"
              required=""
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              required=""
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="City"
              required=""
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <select className="custom-select" defaultValue={""}>
              <option value="">State </option>
              <option value="Gujarat">Gujarat</option>
              <option value="Delhi">Delhi</option>
            </select>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder=" Country"
              required=""
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="PAN Number
											"
              required=""
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Amount"
              required=""
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <input type="submit" className="btn green-btn" value="Pay Now" />
          </div>
        </div>
      </div>
    </form>
  );
};

export default DonationForm;
