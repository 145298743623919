import React from "react";

const Modal = ({
  showModal,
  closeModal,
  heading,
  summary,
  imgUrl,
  bgBlurr,
  className
}) => {
  return (
    <div
      className={`modal fade ${showModal ? "show" : ""} background-blur
        }`}
      style={
        showModal ? { display: "block", overscrollBehavior: "contain" } : {}
      }
      id="myModal"
      onClick={() => closeModal(false)}
    >
      <div
        className={"modal-dialog modal-lg" + (className ?? "")}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title sub-title-2">{heading}</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => closeModal(false)}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              {imgUrl && (
                <div className="col-md-4">
                  <div className="founder-img">
                    <img src={imgUrl} alt="" />
                  </div>
                </div>
              )}
              <div className={imgUrl ? "col-md-8" : "col-md-12"}>
                <div className="founder-detail">
                  <div
                    className="html_render"
                    dangerouslySetInnerHTML={{ __html: summary }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
