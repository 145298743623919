import React from "react";
import ErrorsList from "../ErrorsList";
import {
  checkIsSelected,
  checkSpecialCharacters,
} from "../../Utils/validation/validators";

const WorkExpRow = ({
  isSubmitting,
  register,
  index,
  errors,
  isDirty,
  employmentNature,
  lastItem,
  getValues,
  remove,
}) => {
  const validateEmploymentId = (v) => checkIsSelected(v, "Employment Nature");
  const validateEndDate = (v) => {
    const startDate = getValues(`forms.${index}.StartDate`);
    const endDate = v;
    if (!startDate) {
      return true;
    }
    return endDate >= startDate || "End date can not be before start date.";
  };
  return (
    <>
      <div className={`row pb-2 ${lastItem ? "" : "border-bottom"}`}>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${errors?.JobTitle && isDirty
                ? "red-placeholder border border-danger"
                : ""
                }`}
              placeholder={
                errors?.JobTitle && isDirty
                  ? errors?.JobTitle.message
                  : "Job Title"
              }
              {...register(`forms.${index}.JobTitle`, {
                required: "Please enter Job title",
                validate: (v) => checkSpecialCharacters(v, "Job Title"),
              })}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <select
              className={`custom-select ${errors?.EmploymentNatureId && isDirty
                ? "red-placeholder border border-danger"
                : ""
                }`}
              {...register(`forms.${index}.EmploymentNatureId`, {
                required: "Please select employmentNature",
                validate: validateEmploymentId,
              })}
              disabled={isSubmitting}
            >
              <option key={0} value={0}>
                Employment Nature
              </option>
              {employmentNature.length > 0 &&
                employmentNature.map((item) => (
                  <option
                    key={item.EmploymentNatureId}
                    value={item.EmploymentNatureId}
                  >
                    {item.EmpNatureDesc.toUpperCase()}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${errors?.CompanyName && isDirty
                ? "red-placeholder border border-danger"
                : ""
                }`}
              placeholder={
                errors?.CompanyName && isDirty
                  ? errors?.CompanyName.message
                  : "Company Name"
              }
              {...register(`forms.${index}.CompanyName`, {
                required: "Please enter company name",
                validate: (v) => checkSpecialCharacters(v, "Company Name"),
              })}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${errors?.StartDate && isDirty
                ? "red-placeholder border border-danger"
                : ""
                }`}
              placeholder={
                errors?.StartDate && isDirty
                  ? errors?.StartDate.message
                  : "Start Date"
              }
              {...register(`forms.${index}.StartDate`, {
                max: {
                  value: new Date().toLocaleDateString(),
                  message: "Please select a valid Start Date",
                },
              })}
              onFocus={(e) => {
                e.target.type = "date";
              }}
              onBlur={(e) => {
                e.target.type = "text";
              }}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${errors?.EndDate && isDirty
                ? "red-placeholder border border-danger"
                : ""
                }`}
              placeholder={
                errors?.EndDate && isDirty
                  ? errors?.EndDate.message
                  : "End Date"
              }
              {...register(`forms.${index}.EndDate`, {
                max: {
                  value: new Date().toLocaleDateString(),
                  message: "Please select a valid End Date",
                },
                validate: validateEndDate,
              })}
              onFocus={(e) => {
                e.target.type = "date";
              }}
              onBlur={(e) => {
                e.target.type = "text";
              }}
              disabled={isSubmitting}
            />
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${errors?.JobLocation && isDirty
                ? "red-placeholder border border-danger"
                : ""
                }`}
              placeholder={
                errors?.JobLocation && isDirty
                  ? errors?.JobLocation.message
                  : "Job Location"
              }
              {...register(`forms.${index}.JobLocation`, {
                required: "Please enter job location",
                validate: (v) => checkSpecialCharacters(v, "Job Location"),
              })}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${errors?.LeavingReason && isDirty
                ? "red-placeholder border border-danger"
                : ""
                }`}
              placeholder={
                errors?.LeavingReason && isDirty
                  ? errors?.LeavingReason.message
                  : "Leaving Reason"
              }
              {...register(`forms.${index}.LeavingReason`, {
                required: "Please enter Leaving reason",
                validate: (v) => checkSpecialCharacters(v, "Leaving Reason"),
              })}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end">
          <button
            className="btn btn-danger btn-sm"
            onClick={() => {
              remove(index);
            }}
          >
            <i className="fas fa-bin"></i> Delete
          </button>
        </div>
      </div>
      <ErrorsList isDirty={isDirty} errors={errors} />
    </>
  );
};

export default WorkExpRow;
