export const pincodeValidate = (v) => {
  const regexExp = /^\d+$/;
  return regexExp.test(v) || "Please enter a valid Pin Code";
};

export const phoneValidate = (v) => {
  const regexExp = /^\d+$/;
  return regexExp.test(v) || "Please enter a valid mobile number";
};

export const checkIsSelected = (v, name) => {
  return v != "0" || `Please select ${name}`;
};
export const checkOnlyNumber = (v, name) => {
  return (
    /^\d+$/.test(v) || `Only numbers allowed in ${name}`
  );
}
export const checkSpecialCharacters = (v, name) => {
  return (
    /^[a-zA-Z 0-9]+$/.test(v) || `Special characters not allowed in ${name}`
  );
};

export const checkNameSpecialCharacters = (v, name) => {
  if (/^[.-]/.test(v) || /[.-]$/.test(v)) {
    return `The ${name} cannot start or end with a period (.) or hyphen (-).`;
  }
  return /^[A-Za-z0-9 .-]+$/.test(v) || `Special characters and numbers are not allowed in ${name}.`;
};

export const checkOnlyCharacters = (v, name) => {
  return (
    /^[A-Z a-z]+$/.test(v) ||
    `Special characters and numbers are not allowed in ${name}.`
  );
};
export const checkOnlyNumbers = (v, name) => {
  return /^[0-9]+$/.test(v) || `Only numbers are allowed ${name}`;
};
