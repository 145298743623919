import React, { useState } from "react";
import PersonalUpdate from "../../Components/Forms/PersonalUpdate";
import EducationUpdate from "../../Components/Forms/EducationUpdate";
import WorkExpUpdate from "../../Components/Forms/WorkExpUpdate";

const MemberUpdateProfile = () => {
  const tabs = [0, 1, 2];
  const [activeTab, setactiveTab] = useState(0);
  return (
    <div className="update-profile-body mb-5">
      <h2 className="main-title mt-3 mb-3">Update Your Profile</h2>

      <section className="update-profile-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              <div className="wizard memnership-form">
                <div className="wizard-inner">
                  <div className="connecting-line"></div>
                  <ul
                    className="nav nav-tabs presentation-list"
                    role="tablist"
                    style={{ zIndex: 0 }}
                  >
                    <li
                      role="presentation"
                      className={activeTab === tabs[0] ? "active" : "disabled"}
                      onClick={() => setactiveTab(tabs[0])}
                    >
                      <a href="#" role="tab">
                        Personal Info
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className={activeTab === tabs[1] ? "active" : "disabled"}
                      onClick={() => setactiveTab(tabs[1])}
                    >
                      <a href="#" role="tab">
                        Education Detail
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className={activeTab === tabs[2] ? "active" : "disabled"}
                      onClick={() => setactiveTab(tabs[2])}
                    >
                      <a href="#" role="tab">
                        Work Exp Details
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="tab-content mb-4" id="main_form">
                  {activeTab === tabs[0] && <PersonalUpdate />}
                  {activeTab === tabs[1] && <EducationUpdate />}
                  {activeTab === tabs[2] && <WorkExpUpdate />}
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MemberUpdateProfile;
