import React from 'react'
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

const CustomToast = ({
    onClose,
    show,
    message,
    type,
    isExiting
}) => {
    return (
        <Toast
            onClose={() => onClose(false)}
            show={show}
            delay={3000}
            autohide
            bg={type}
            className={`toast-custom ${isExiting ? 'toast-custom-exit' : ''}`}
            style={{
                minWidth: "200px",
                color: "white",
            }}
        >
            <Toast.Body>
                {message}
            </Toast.Body>
        </Toast>
    );
}

export default CustomToast