import React, { useContext, useEffect, useRef, useState } from "react";
import { getApiResponse } from "../../Utils/AxiosRequest";
import {
  API_PATHS,
  errorMessages,
  RESULTS_PER_PAGE,
  URL_PATH,
} from "../../Utils/Constants/constants";
import ConditionalLoader from "../../Components/ConditionalLoader";
import Modal from "../../Components/Modal";
import SessionTimoutPopup from "../../Components/SessionTimoutPopup";
import CustomPagination from "../../Components/CustomPagination";
import CustomButton from "../../Components/CustomButton";
import deleteIcon from '../../Assets/icons/delete.png';
import editIcon from '../../Assets/icons/edit.png';
import viewIcon from '../../Assets/icons/view.png';
import ConfirmationPopup from "../../Components/ConfirmationPopup";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import ViewJobPopup from "../../Components/ViewJobPopup";
import CenterModal from "../../Components/CenterModal";
import { MemberDetails } from "../../Contexts/MemberDetailContext";

const MemberJobDashboard = () => {
  const { jobCount, setJobCount } = useContext(MemberDetails);
  const [jobs, setjobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState('');
  const [showJob, setShowJob] = useState('');
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const [sessionTimeout, setSessionTimeout] = useState(false);
  const jobsList = useRef([]);
  const navigate = useNavigate();
  const { updateToastMessage } = useOutletContext()

  const sortedJobs = jobs.sort((a, b) => {
    if (sortOrder === "0") {
      return 0;
    } else if (sortOrder === "1") {
      return a.JobName.localeCompare(b.JobName);
    } else {
      return b.JobName.localeCompare(a.JobName);
    }
  });
  const noSearchResult = jobs.length === 0 && searchQuery;
  const [currentPage, setcurrentPage] = useState(0);
  const totalPages = Math.ceil(jobs.length / RESULTS_PER_PAGE);

  const closePopup = () => {
    setShowModal(false);
    setMessage({
      heading: "",
      description: "",
    });
  };
  const handleSOrtChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleQueryChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value === "") {
      setjobs(jobsList.current);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (searchQuery.trim()) {
      setjobs(jobs.filter(item => item.JobName.includes(searchQuery.trim()) || item.JobDesignation.includes(searchQuery.trim()) || item.JobLocation.includes(searchQuery.trim())))
    }
    setIsLoading(false);
  };
  const handleEdit = (e, id) => {
    e.preventDefault()
    navigate(URL_PATH.editJob + `/${id}`);
  }
  const handleView = (e, id) => {
    e.preventDefault()
    setShowJob(id);
  }
  const closeJobPopup = () => {
    setShowJob('');
  }
  const handleDeleteJobClick = (e, id) => {
    e.preventDefault();
    setShowConfirmDelete(id);
  }
  const confirmDelete = async () => {
    setIsDeleting(true)
    const response = await getApiResponse(API_PATHS.deleteJob + `?jobId=${showConfirmDelete}`, true);
    setShowConfirmDelete('');
    if (response.status === 200) {
      updateToastMessage("Job Deleated Successfully.", "success");
      setJobCount(jobCount - 1);
    } else if (response.status === 401) {
      setSessionTimeout(true);
    } else {
      updateToastMessage(errorMessages.error, "danger");
    }
    setIsDeleting(false);
  }
  const cancelDelete = () => {
    setShowConfirmDelete('')
  }
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await getApiResponse(API_PATHS.viewJobs, true);
      if (response.status === 200) {
        setjobs(response.data ?? []);
        jobsList.current = response.data ?? [];
      } else if (response.status === 401) {
        setSessionTimeout(true);
        setcurrentPage(0);
      } else {
        updateToastMessage(errorMessages.error, "danger");

      }
      setIsLoading(false);
    };
    if (!isDeleting) {
      fetchData();
    }
  }, [isDeleting]);

  return (
    <SessionTimoutPopup isSessionTimout={sessionTimeout}>
      <div className="update-profile-body">
        <div className="row pb-2">
          <h2 className="col-md-6 main-title mt-3 mb-3">View Jobs</h2>
          <div className='col-md-6 d-flex justify-content-end' style={{ height: 'fit-content' }}>
            <Link className='btn green-btn bg-green py-2 rounded-md' to={URL_PATH.memberaddjob}>Create Job</Link>
          </div>
        </div>
        <section className="search-member-body">
          <form className="common-form">
            <div className="row">
              <div className="col-md-8">
                <div
                  className="input-group searchbar-row"
                  style={{
                    zIndex: 0,
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="searchTxt"
                    placeholder="Search"
                    onChange={handleQueryChange}
                    autoComplete="off"

                  />
                  <div className="input-group-append">
                    <button
                      className="btn"
                      type="submit"
                      disabled={isLoading}
                      onClick={handleSearch}
                    >
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <select className="custom-select" onChange={handleSOrtChange}>
                    <option value="0">Sort</option>
                    <option value="1">Sort by A-Z</option>
                    <option value="-1">Sort by Z-A</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive custome-data-table">
            <ConditionalLoader isLoading={isLoading}>
              <div style={{ minHeight: "500px" }}>
                {jobs.length ? <table
                  className="table"
                >
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Designation</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedJobs.length > 0 &&
                      sortedJobs
                        .slice(
                          (currentPage) * RESULTS_PER_PAGE,
                          (currentPage + 1) * RESULTS_PER_PAGE
                        )
                        .map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.JobName} </td>
                            <td>{item.JobDesignation}</td>
                            <td>
                              <div>
                                <span>{item.IsApprovedStatus}</span>
                                {item.IsApprovedStatus === "Pending"
                                  ? <div style={{ display: 'inline-block', width: '10px', height: '10px' }} className="bg-primary rounded-circle ml-2"></div>
                                  : item.IsApprovedStatus === "Approved" ? <div style={{ display: 'inline-block', width: '10px', height: '10px' }} className="bg-success rounded-circle ml-2"></div>
                                    : <div style={{ display: 'inline-block', width: '10px', height: '10px' }} className="bg-danger rounded-circle ml-2"></div>
                                }
                              </div>
                            </td>
                            <td>
                              <div className='d-flex justify-content-start gap-2 align-items-start'>
                                <CustomButton className='btn btn-sm' onClick={(e) => handleView(e, item.MemberJobId)} label="View Job">
                                  <img height={24} width={24} src={viewIcon} alt="view" />
                                </CustomButton>
                                <CustomButton className='btn btn-sm' onClick={(e) => {
                                  if (item.IsApproved === null) {
                                    handleEdit(e, item.MemberJobId)
                                  }
                                }} disabled={item.IsApproved !== null} label={item.IsApproved === null ? "Edit Job" : "Can't Edit"}>
                                  <img height={24} width={24} src={editIcon} alt="edit" />
                                </CustomButton>
                                <CustomButton className='btn btn-sm' onClick={(e) => handleDeleteJobClick(e, item.MemberJobId)} label="Delete Job">
                                  <img height={24} width={24} src={deleteIcon} alt="delete" />
                                </CustomButton>
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table> : <p className='p-4 text-center h4'>No Job found <Link to={URL_PATH.memberaddjob}>Create One</Link></p>}
              </div>
              {noSearchResult ? (
                <p className="p-4 text-center">
                  <b>No Search result found !!</b>
                </p>
              ) : (
                <div className="d-flex justify-content-center pagination">
                  {totalPages > 0 && (
                    <CustomPagination pageNumber={currentPage} setpageNumber={setcurrentPage} numberOfPages={totalPages} />
                  )}
                </div>
              )}
            </ConditionalLoader>
          </div>
        </section>
        <CenterModal
          show={showModal}
          onClose={closePopup}
          heading={message.heading}
          description={message.description}
        />
      </div>
      <ConfirmationPopup showModal={showConfirmDelete} closeModal={cancelDelete}
        confirmCallback={confirmDelete}
        denyCallback={cancelDelete}
        heading={"Confirm delete Job"} />
      <ViewJobPopup showModal={showJob} closeModal={closeJobPopup} id={showJob} />
    </SessionTimoutPopup>
  );
};

export default MemberJobDashboard;
