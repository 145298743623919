import React, { useContext, useState } from "react";

import { ApiDataContext } from "../../Contexts/ApiData";
import ButtonSlider from "../../Components/Carousel/ButtonSlider";
import NewsCard from "../../Components/Cards/NewsCard";
import UEvenetCard from "../../Components/Cards/UEvenetCard";
import Banner from "../../Components/Banner";
import { useNavigateToEvent, useNavigateToNews } from "../../Hooks/Navigation";

const Events = () => {
  const [displayRecords, setDisplayRecords] = useState(6);

  const apiData = useContext(ApiDataContext);

  const navigateToEvent = useNavigateToEvent();

  const pageBanner = apiData?.PageBanner;
  const eventList = apiData?.Events;
  const latestEventList = apiData?.LatestEvent;

  return (
    <>
      {pageBanner && pageBanner.length ? (
        <Banner
          heading={pageBanner[0].PageBannerTitle}
          imgUrl={pageBanner[0].DisplayURL}
          alt={"Events"}
        />
      ) : (
        <></>
      )}
      {eventList && eventList.length ? (
        <section className="upcoming-event">
          <div className="container">
            <div className="monin-service-title">
              <h3 className="main-title text-center mb-3">AIMC's All Events</h3>
            </div>
            <div className="row">
              {eventList.slice(0, displayRecords).map((event, index) => (
                <UEvenetCard
                  key={event?.EventId ?? index}
                  imgUrl={event?.DisplayURL}
                  date={event?.ScheduleDate.slice(0, 10)}
                  heading={event?.EventTitle}
                  location={event?.EventLocation}
                  summary={event?.EventContent}
                  readmore={() => navigateToEvent(event?.EventId)}
                />
              ))}
            </div>

            {displayRecords < eventList.length && (
              <div className="mt-5">
                <div className="text-center">
                  <button
                    className="btn green-btn"
                    id="loadMore"
                    onClick={() => setDisplayRecords((prvs) => prvs + 3)}
                  >
                    Load More
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
      ) : (
        <></>
      )}
      {latestEventList && latestEventList.length ? (
        <section className="latest-news">
          <div className="container">
            <h2 className="main-title mb-3">Latest News</h2>
            <div className="latest-news-row">
              <ButtonSlider>
                <>
                  {latestEventList.map((event, index) => (
                    <NewsCard
                      key={index}
                      imgUrl={event?.DisplayURL}
                      date={event?.ScheduleDate.slice(0, 10)}
                      heading={event?.EventTitle}
                      summary={event?.EventContent}
                      readmore={() => navigateToEvent(event?.EventId)}
                    />
                  ))}
                </>
              </ButtonSlider>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default Events;
