import React, { useEffect, useState } from "react";
import MatrimonialProfileCard from "../../Components/Cards/MatrimonialProfileCard";
import { API_PATHS, errorMessages } from "../../Utils/Constants/constants";
import { getApiResponse, postData } from "../../Utils/AxiosRequest";
import ConditionalLoader from "../../Components/ConditionalLoader";
import ViewProfilePopup from "../../Components/ViewProfilePopup";

const MatrimonialDashboard = () => {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [nationalities, setNationalities] = useState([]);
  const [genders, setGenders] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [religions, setReligions] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [skinColor, setSkinColor] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [motherTounge, setMotherTounge] = useState([]);
  const [profileCatagory, setProfileCatagory] = useState([]);
  const [annualIncome, setAnnualIncome] = useState([]);
  const [matrimonialProfiles, setMatrimonialProfiles] = useState([]);
  const [pageError, setError] = useState("");
  const [viewProfile, setViewProfile] = useState({});
  const closeViewProfile = () => {
    setViewProfile(prvs => ({
      ...prvs,
      show: false,
    }))
  }

  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sessionTImeout, setSessionTimeout] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });

  const fetchProfiles = async () => {
    try {

    } catch (error) {
      setError(errorMessages.error);
    }
  }
  const getYearDifference = (d) => {
    if (!d) {
      return ''
    }
    const dateToday = new Date().getFullYear();
    const givenDate = new Date(d).getFullYear();
    const diff = dateToday - givenDate
    return diff
  }

  const handleViewProfile = (value) => {
    setViewProfile({ show: true, ...value, CityName: value.DistrictName, State: value.StateName })
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        const [
          response,
          genderResponse,
          designationResponse,
          countriesResponse,
          statesResponse,
          districtResponse,
          categoriesResponse,
          maritalStatusResponse,
          bodyTypeResponse,
          relegionResponse,
          skinColorResponse,
          motherToungeResponse,
          annualIncomeResponse,
          occupationResponse,
          profileCatagoryResponse,
          matrimonialProfiles
        ] = await Promise.all([
          getApiResponse(API_PATHS.getNationalities),
          getApiResponse(API_PATHS.getGenders),
          getApiResponse(API_PATHS.getDesignations),
          getApiResponse(API_PATHS.getCountries),
          getApiResponse(API_PATHS.getStates),
          getApiResponse(API_PATHS.getDisticts),
          getApiResponse(API_PATHS.getCategories),
          getApiResponse(API_PATHS.getMaritalStatus),
          getApiResponse(API_PATHS.getBodyType),
          getApiResponse(API_PATHS.getRelegios),
          getApiResponse(API_PATHS.getComplexions),
          getApiResponse(API_PATHS.getMotherTounges),
          getApiResponse(API_PATHS.getAnnualIncomes),
          getApiResponse(API_PATHS.getOccupations),
          getApiResponse(API_PATHS.getProfileCategories),
          postData(API_PATHS.filterMatrimonialProfile, {}, { bearer: true })
        ])

        if (response.status === 200 && response.IsSuccess) {
          setNationalities(response.data);
        }

        if (genderResponse.status === 200 && genderResponse.IsSuccess) {
          setGenders(genderResponse.data ?? []);
        }

        if (designationResponse.status === 200 && designationResponse.IsSuccess) {
          setDesignations(designationResponse.data ?? []);
        }

        if (countriesResponse.status === 200 && countries.IsSuccess) {
          setCountries(countriesResponse.data ?? []);
        }

        if (statesResponse.status === 200 && statesResponse.IsSuccess) {
          setStates(statesResponse.data ?? []);
        }

        if (districtResponse.status === 200 && districtResponse.IsSuccess) {
          setDistricts(districtResponse.data ?? []);
        }

        if (categoriesResponse.status === 200 && categoriesResponse.IsSuccess) {
          setCategories(categoriesResponse.data ?? []);
        }

        if (maritalStatusResponse.status === 200 && matrimonialProfiles.IsSuccess) {
          setMaritalStatus(maritalStatusResponse.data ?? []);
        }

        if (bodyTypeResponse.status === 200 && bodyTypeResponse.IsSuccess) {
          setBodyTypes(bodyTypeResponse.data ?? []);
        }

        if (relegionResponse.status === 200 && relegionResponse.IsSuccess) {
          setReligions(relegionResponse.data ?? []);
        }

        if (skinColorResponse.status === 200 && skinColorResponse.IsSuccess) {
          setSkinColor(skinColorResponse.data ?? []);
        }

        if (motherToungeResponse.status === 200 && motherToungeResponse.IsSuccess) {
          setMotherTounge(motherToungeResponse.data ?? []);
        }

        if (annualIncomeResponse.status === 200 && annualIncomeResponse.IsSuccess) {
          setAnnualIncome(annualIncomeResponse.data ?? []);
        }

        if (occupationResponse.status === 200 && occupationResponse.IsSuccess) {
          setOccupation(occupationResponse.data ?? []);
        }

        if (profileCatagoryResponse.status === 200 && profileCatagoryResponse.IsSuccess) {
          setProfileCatagory(profileCatagoryResponse.data ?? []);
        }
        console.log(matrimonialProfiles);

        if (matrimonialProfiles.status === 200 && matrimonialProfiles.data.IsSuccess) {
          console.log(matrimonialProfiles.data?.Response);
          setMatrimonialProfiles(matrimonialProfiles.data?.Response ?? []);
        }
      } catch (error) {
        setError(errorMessages.error);
      }
      setIsLoadingData(false);
    };
    fetchData();
  }, []);


  return (
    <ConditionalLoader isLoading={isLoadingData} error={pageError} >
      <div className="container">
        <div className="update-profile-body">
          <h2 className="main-title mt-3 mb-3">Search for a perfect partner</h2>
          <div>
            <form className="common-form">
              <div className="row">
                <div className="col-md-3">
                  <label className="d-block w-100">Search For:</label>
                  <div className="form-group aimc-radio" >
                    <div className="custom-control custom-radio" style={{
                      zIndex: 0
                    }} >
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="customRadio-1"
                        name="example1"
                        value="Bride"
                      />
                      <label className="custom-control-label" htmlFor="customRadio-1">
                        Bride
                      </label>
                    </div>
                    <div className="custom-control custom-radio" style={{
                      zIndex: 0
                    }} >
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="customRadio-2"
                        name="example1"
                        value="Groom"
                      />
                      <label className="custom-control-label" htmlFor="customRadio-2">
                        Groom
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                    <div className="d-flex">
                      <select className="custom-select mr-3">
                        <option>Min-Age</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                      </select>
                      <select className="custom-select mr-3">
                        <option value="">Max-Age</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                      </select>
                      <div>
                        <input
                          type="submit"
                          value="Search"
                          className="btn green-btn"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="matrimonial-container">
            <div className="row">
              {
                matrimonialProfiles.map((data) => (
                  <MatrimonialProfileCard
                    key={data.MemberMatrimonial_Id}
                    age={getYearDifference(data.DOB)}
                    gender={data.Gender}
                    id={data.MemberMatrimonial_Id}
                    imgUrl={data?.DisplayURL}
                    location={data.DistrictName ?? 'N/A'}
                    name={data.FirstName ?? '' + data.LastName ?? ''}
                    viewMore={(e) => {
                      e.preventDefault()
                      handleViewProfile(data)
                    }}
                  />
                ))
              }
            </div>
          </div>
        </div>
        <ViewProfilePopup
          show={viewProfile.show}
          onClose={closeViewProfile}
          details={viewProfile}
        />
      </div>
    </ConditionalLoader>
  );
};

export default MatrimonialDashboard;
