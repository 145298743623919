import React, { Component } from 'react'

export class ErrorBoundaty extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (<div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ minHeight: "500px" }}
            >
                <h1>Error Occured.</h1>
            </div>);
        }

        return this.props.children;
    }
}

export default ErrorBoundaty