import React, { useContext } from "react";

import { ApiDataContext } from "../../Contexts/ApiData";
import UserCard from "../../Components/Cards/UserCard";
import SquareImageSlider from "../../Components/Carousel/SquareImageSlider";
import Banner from "../../Components/Banner";

const About = () => {
  const apiData = useContext(ApiDataContext);

  const pageBanner = apiData?.PageBanner;
  const visionImage = apiData?.CMSPage?.Display_VisionImagePath;
  const goalImage = apiData?.CMSPage?.Display_GoalImagePath;
  const presidentPhoto = apiData?.CMSPage?.Display_PresidentPhoto;
  const goalContent = apiData?.CMSPage?.GoalContent;
  const presidentMessage = apiData?.CMSPage?.PresidentMessage;
  const presidentContent = apiData?.CMSPage?.PresidentContent;
  const visionContent = apiData?.CMSPage?.VisionContent;
  const formerLeaders = apiData?.FormerLeaders?.sort((a, b) => (a.SortOrder - b.SortOrder));
  return (
    <>
      {pageBanner && pageBanner.length ? (
        <Banner
          heading={pageBanner[0].PageBannerTitle}
          imgUrl={pageBanner[0].DisplayURL}
          alt={"About Us"}
        />
      ) : (
        <></>
      )}
      <section className="our-vision-goal">
        <div className="container">
          <div className="our-vision">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div>
                  <h3 className="main-title">Our Vision</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: visionContent }}
                  ></div>
                </div>
              </div>
              <div className="col-md-6">
                <SquareImageSlider images={[visionImage]} />
              </div>
            </div>
          </div>
          <div className="our-goal">
            <div className="row align-items-center">
              <div className="col-md-6">
                <SquareImageSlider images={[goalImage]} />
              </div>
              <div className="col-md-6">
                <div>
                  <h3 className="main-title">Our Goal</h3>
                  <div dangerouslySetInnerHTML={{ __html: goalContent }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="president-message">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="president-img">
                <img src={presidentPhoto} alt="" />
              </div>
              <h2 className="main-title mb-3">{presidentMessage}</h2>
              <div dangerouslySetInnerHTML={{ __html: presidentContent }}></div>
            </div>
          </div>
        </div>
      </section>
      {formerLeaders && formerLeaders.length ? (
        <section className="board-management the-presidents">
          <div className="container">
            <div className="monin-service-title">
              <h2 className="sub-title text-center mb-3">Former Presidents</h2>
              <h3 className="main-title text-center mb-5">
                {" "}
                Presidents members Of All India Momin Conferance
              </h3>
            </div>
            <div className="row justify-content-center">
              {formerLeaders?.map((leader) => (
                <UserCard
                  key={leader?.LeaderId}
                  name={leader?.LeaderName}
                  summary={leader?.LeaderDesignation}
                  duration={leader?.Duration}
                  imgUrl={leader?.DisplayURL}
                />
              ))}
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default About;
