import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const getApiResponse = async (path, header) => {
  let config = {};
  if (header) {
    const token =
      sessionStorage.getItem("token") ?? localStorage.getItem("token") ?? "";
    config.headers = { Authorization: `Bearer ${token}` };
  }
  try {
    const response = await axiosInstance.get(path, config);
    if (!response.data.IsSuccess) {
      return {
        status: 500,
        data: null,
        message: response.Message,
      };
    }
    return {
      status: response.status,
      data: response.data.Response ?? {},
      message: response.data.Message,
    };
  } catch (error) {
    console.error(error);
    return {
      status: error.code === "ERR_NETWORK" ? 401 : 500,
      data: null,
      message: error.message,
    };
  }
};

export const postData = async (path, data, requiedField) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (requiedField?.formData) {
    config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }

  if (requiedField?.bearer) {
    const token =
      sessionStorage.getItem("token") ?? localStorage.getItem("token") ?? "";
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  }

  try {
    const response = await axiosInstance.post(path, data, config);
    return {
      status: response.status,
      data: response.data ?? {},
    };
  } catch (error) {
    console.error(error);
    return {
      status: error.code === "ERR_NETWORK" ? 401 : 500,
      message: error.message,
    };
  }
};
