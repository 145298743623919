import React, { useContext, useEffect, useState } from "react";
import { MemberDetails } from "../../Contexts/MemberDetailContext";
import { useFieldArray, useForm } from "react-hook-form";
import { getApiResponse, postData } from "../../Utils/AxiosRequest";
import {
  API_PATHS,
  errorMessages
} from "../../Utils/Constants/constants";
import WorkExpRow from "./WorkExpRow";
import ConditionalLoader from "../ConditionalLoader";
import { Spinner } from "react-bootstrap";
import Modal from "../Modal";
import { useOutletContext } from "react-router-dom";
import SessionTimoutPopup from "../SessionTimoutPopup";
import CenterModal from "../CenterModal";

const WorkExpUpdate = () => {
  const {
    memberDetails,
    workExperience,
    setWorkExperience,
    isLoading,
    setIsUpdated,
  } = useContext(MemberDetails);
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [employmentNature, setEmploymentNature] = useState([]);
  const [isLoadingForm, setIsLoadingForm] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const [sessionTimeout, setSessionTimeout] = useState(false);
  const [isExpUpdated, setisExpUpdated] = useState(false);
  const { updateToastMessage } = useOutletContext();
  const workExpDetails = workExperience.map((item) => ({
    EmploymentNatureId: item.EmploymentNatureId,
    JobTitle: item.JobTitle,
    StartDate: item.StartDate.slice(0, 10),
    EndDate: item.EndDate.slice(0, 10),
    CompanyName: item.CompanyName,
    JobLocation: item.JobLocation,
    LeavingReason: item.LeavingReason,
  }));

  const workExpTemp = {
    StartDate: "",
    EndDate: "",
    EmploymentNatureId: "0",
    JobTitle: "",
    CompanyName: "",
    JobLocation: "",
    LeavingReason: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      forms: workExperience.length ? workExpDetails : [workExpTemp],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "forms",
  });

  const addWorkExpRow = (e) => {
    e.preventDefault();
    append(workExpTemp);
  };
  const closePopup = () => {
    setShowModal(false);
    setMessage({
      heading: "",
      description: "",
    });
    if (isExpUpdated) {
      setIsUpdated(true);
    }
  };

  const updateWorkExp = async (data) => {
    setIsSubmiting(true);
    const requestBody = data.forms.map((item) => ({
      ...item,
      MemberId: memberDetails.MemberId,
      CreatedBy: memberDetails.MemberId,
      ModifiedBy: memberDetails.MemberId
    }));
    try {
      const response = await postData(API_PATHS.updateExperience, requestBody, {
        bearer: true,
      });
      switch (response.status) {
        case 200:
          if (response.data.IsSuccess) {
            updateToastMessage("Work experience updated successfully.", "success");

            setisExpUpdated(requestBody);
          } else {
            updateToastMessage(errorMessages.error, "danger");
          }
          break;
        case 400:
          updateToastMessage("Validation Error.", "danger");
          break;
        case 401:
          setSessionTimeout(true);
          break;
        default:
          updateToastMessage(errorMessages.error, "danger");
      }
    } catch (error) {
      console.error("Error:", error);
      updateToastMessage(errorMessages.error, "danger");
    }
    setIsSubmiting(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingForm(true);
      try {
        const employmentResponse = await getApiResponse(
          API_PATHS.getEmploymentNatures
        );
        if (employmentResponse.status === 200) {
          setEmploymentNature(employmentResponse.data);
        }
      } catch (error) {
        console.error("Error", error);
      }
      setIsLoadingForm(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setValue("forms", workExpDetails);
  }, [workExperience]);

  return (
    <ConditionalLoader isLoading={isLoading || isLoadingForm}>
      <SessionTimoutPopup isSessionTimout={sessionTimeout}>
        <form onSubmit={handleSubmit(updateWorkExp)}>
          <div className="tab-pane">
            <h4 className="sub-title mb-4">Work Exp Details</h4>
            {fields.map((item, index) => (
              <WorkExpRow
                key={index}
                index={index}
                register={register}
                isSubmitting={isSubmitting}
                errors={errors?.forms?.[index]}
                employmentNature={employmentNature}
                isDirty={isDirty}
                item={item}
                lastItem={index === fields.length - 1}
                setValue={setValue}
                getValues={getValues}
                remove={remove}
              />
            ))}
            <div>
              <button
                className="btn green-btn mt-4"
                id="add-education-btn"
                onClick={addWorkExpRow}
              >
                <i className="fas fa-plus"></i> Add Row
              </button>
            </div>
            <ul className="w-full list-inline text-right d-flex justify-content-end">
              <li>
                {isSubmitting ? (
                  <button className="btn green-btn px-5 cursor-na" disabled>
                    <Spinner size="sm" />
                  </button>
                ) : (
                  <input
                    type="submit"
                    className="btn green-btn"
                    value="Save"
                    disabled={
                      errors?.forms?.length > 0 || !isDirty || isSubmitting
                    }
                  />
                )}
              </li>
            </ul>
          </div>
        </form>
        <CenterModal
          show={showModal}
          onClose={closePopup}
          heading={message.heading}
          description={message.description}
        />
      </SessionTimoutPopup>
    </ConditionalLoader>
  );
};

export default WorkExpUpdate;
