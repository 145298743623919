import React from "react";
import { getDateTime, getLimitHtmlContent } from "../../Utils/format";

const UEvenetCard = ({
  imgUrl,
  heading,
  location,
  date,
  className,
  readmore,
  url
}) => {
  const dateTime = getDateTime(date);
  const attributes = {
    onClick: readmore ? (e) => {
      e.preventDefault();
      readmore()
    } : () => { },
    href: url ? url : "#"
  }
  return (
    <div className={`col-lg-4 mt-4 ${className}`}>
      <div className="event-2">
        <div className="event-img">
          <img src={imgUrl} alt="" />
        </div>
        <div className="event-body">
          <h3 className="pointer">
            <a
              href="#"
              className="sub-title-2 d-block"
              {...attributes}
            >
              {getLimitHtmlContent(heading, 90)}
            </a>
          </h3>
          {dateTime && (
            <div className="recent-event-date">
              <i className="far fa-calendar-alt green-text"></i>{" "}
              <span className="pl-2">{dateTime}</span>{" "}
            </div>
          )}
          {location && (
            <div className="recent-event-date">
              <i className="fas fa-map-marker-alt green-text"></i>{" "}
              <span className="pl-2">{location} </span>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UEvenetCard;
