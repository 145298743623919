export const URL_PATH = {
  home: "/",
  history: "/history",
  about: "/about",
  social: "/social-responsibility",
  gallery: "/gallery",
  contact: "/contactus",
  news: "/news",
  newsbyid: "/news",
  events: "/events",
  eventbyid: "/event",
  donation: "/donation",
  jobs: "/jobs",
  forgotpassword: "/forgotpassword",
  login: "/login",
  membership: "/membership",
  payment: "/payment",
  memberhome: "/home",
  memberprofile: "/profile",
  memberprofileedit: "/profile/edit",
  searchmember: "/profile/search",
  memberprofilebyid: "/profile/search/:id",
  memberjobdashboard: "/dashboard/jobs",
  memberaddjob: "/dashboard/jobs/add",
  memberJobById: "/dashboard/jobs/:id",
  editJob: "/dashboard/jobs/edit",
  editjobById: "/dashboard/jobs/edit/:id",
  matrimonialprofiles: "/matrimonial/profiles",
  matrimonialProfileById: "/matrimonial/profiles/:id",
  mymatrimonialprofile: "/matrimonial/myprofile",
  matrimonialdahboard: "/matrimonial/dahboard",
  editMatrimonialProfile: "/matrimonial/profile/edit",
  editMatrimonialProfileById: "/matrimonial/profile/edit/:id",
  createprofile: "/matrimonial/register",
  notfound: "/notfound",
};

export const API_PATHS = {
  // CMS
  header: "/api/Frontend/GetHeaderFooterDetails",
  home: "/api/Frontend/GetHomePageDetails",
  history: "/api/Frontend/GetHistoryPageDetails",
  about: "/api/Frontend/GetAboutPageDetails",
  social: "/api/Frontend/GetSocialResponsibilityDetails",
  gallery: "/api/Frontend/GetGalleryDetails",
  contact: "/api/Frontend/GetContactsDetails",
  news: "/api/Frontend/GetNewsDetails",
  newsbyid: "/api/Frontend/GetNewsDetailsById",
  events: "/api/Frontend/GetEventDetails",
  eventbyid: "/api/Frontend/GetEventDetailsById",
  donation: "/api/Frontend/GetDonationDetails",
  jobs: "/api/Frontend/SearchJob",
  getCategories: "/api/Master/GetCategories",
  getMaritalStatus: "/api/Master/GetMaritalStatuses",
  getRelegios: "/api/Master/GetReligions",
  getBodyType: "/api/Master/GetBodyTypes",
  getComplexions: "/api/Master/GetSkinColors",
  getOccupations: "/api/Master/GetOccupations",
  getAnnualIncomes: "/api/Master/GetAnnualIncomes",
  getMotherTounges: "/api/Master/GetMotherTongues",
  getProfileCategories: "/api/Master/GetMatrimonialProfileCategory",

  // Data
  getNationalities: "/api/Master/GetNationalities",
  getGenders: "/api/Master/GetGenders",
  getDesignations: "/api/Master/GetDesignations",
  getCountries: "/api/Locations/GetCountries",
  getStates: "/api/Locations/GetStates",
  getDisticts: "/api/Locations/GetCities",
  getGrades: "/api/Master/GetDegreeGrades",
  getDegrees: "/api/Master/GetDegrees",
  getEmploymentNatures: "/api/Master/GetEmploymentNatures",
  // Membership
  createMember: "/api/MemberFrontend/CreateMember",
  createPayment: "/api/Payment/CreatePaymentDetails",
  loginMember: "/api/MemberFrontend/LoginMember",
  viewMember: "/api/MemberFrontend/ViewMember",
  updateMember: "/api/MemberFrontend/UpdateMember",
  updateEducation: "/api/MemberFrontend/UpdateMemberEducation",
  updateExperience: "/api/MemberFrontend/UpdateMemberWorkExperience",
  getMember: "/api/MemberFrontend/GetMember",
  viewJobs: "/api/MemberFrontend/ViewJobs",
  createJob: "/api/MemberFrontend/CreateJob",
  deleteJob: "/api/MemberFrontend/DeleteJob",
  viewJob: "/api/MemberFrontend/ViewJob",
  updateJob: "/api/MemberFrontend/UpdateJob",
  searchMember: "/api/MemberFrontend/SearchMember",
  matrimonialProfiles: "/api/MemberFrontend/GetMemberMatrimonial",
  deleteMatmonialProfile: "/api/MemberFrontend/DeleteMemberMatrimonial",
  createMatrimonialProfile: "/api/MemberFrontend/CreateUpdateMatrimonialProfile",
  getMatrimonialProfile: "/api/MemberFrontend/GetMatrimonialProfile",
  getPartnerPreference: "/api/MemberFrontend/GetPartnerPreference",
  updatePartnerPreference: "/api/MemberFrontend/CreateOrUpdatePartnerPreference",
  getMatrimonialGallery: "/api/MemberFrontend/GetMemberMantrimonialGallery",
  deleteGallery: "/api/MemberFrontend/DeleteMemberGallery",
  createGallery: "/api/MemberFrontend/CreateMemberGallery",
  filterMatrimonialProfile: "/api/MemberFrontend/FilterMemberMatrimonial"
};

export const errorMessages = {
  error: "Something went wrong.",
  errorDescription: "Please try again later.",
  verifyInput: "Please verify all fields and resubmit.",
  sessionTimeout: "Session Timeout.",
  sessionTimeoutDescription: "Please login again.",
  submitSuccess: "Submitted Successfully.",
  updateSuccess: "Updated Successfully.",
  refreshInstruction: "Please try to refresh",
  fieldUpdateSuccess: (field) => {
    return `${field} updated successfully.`;
  },
  fieldSubmittedSuccess: (field) => {
    return `${field} submitted successfully.`;
  },
};

export const RESULTS_PER_PAGE = 10;
export const RESULTS_PER_PAGE_12 = 12;
