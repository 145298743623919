import React, { useEffect, useState } from 'react'
import CustomPopup from "../Components/CustomPopup";
import SessionTimoutPopup from './SessionTimoutPopup';
import Modal from './Modal';
import { API_PATHS, errorMessages } from '../Utils/Constants/constants';
import { getApiResponse } from '../Utils/AxiosRequest';
import ConditionalLoader from './ConditionalLoader';
import CenterModal from './CenterModal';

const ViewJobPopup = ({
    showModal,
    closeModal,
    id
}) => {
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [sessionTimeout, setSessionTimeout] = useState(false);
    const [jobDetails, setJobDetails] = useState({});
    const [showMyModal, setShowMyModal] = useState(false);
    const [message, setMessage] = useState({
        heading: "",
        description: ""
    })

    useEffect(() => {
        const fetchData = async () => {
            if (id) {

                setIsLoadingData(true);
                const response = await getApiResponse(API_PATHS.viewJob + `?jobId=${id}`, true)
                if (response.status === 200) {
                    setJobDetails(response.data)
                } else if (response.status === 401) {
                    setSessionTimeout(true);
                } else {
                    setShowMyModal(true);
                    setMessage({
                        heading: errorMessages.error,
                        description: errorMessages.description
                    })
                }
                setIsLoadingData(false);
            }
        }
        fetchData()
    }, [id])


    return (
        <CustomPopup className="modal-lg" showModal={showModal} closeModal={closeModal} heading={"Job Details"}>
            <SessionTimoutPopup isSessionTimout={sessionTimeout}>
                <ConditionalLoader isLoading={isLoadingData} noPref>
                    <div className="view-member-profile-detail">
                        <ul className="view-profile-list">
                            <li>
                                <strong>Name: </strong>{" "}
                                <span>{(jobDetails.JobName ?? "N/A")} </span>
                            </li>
                            <li>
                                <strong>Designation: </strong>{" "}
                                <span>{jobDetails.JobDesignation ?? "N/A"}</span>
                            </li>
                            <li>
                                <strong>Company Name: </strong> <span>{jobDetails.CompanyName ?? ""}</span>
                            </li>
                            <li>
                                <strong>ContactInfo: </strong>{" "}
                                <span>
                                    {jobDetails?.ContactInfo ?? "N/A"}
                                </span>
                            </li>
                            <li>
                                <strong>Location: </strong>{" "}
                                <span>{jobDetails?.JobLocation ?? "N/A"}</span>
                            </li>
                            <li>
                                <strong>Description: </strong>{" "}
                                <span>{jobDetails?.JobDescription ?? "N/A"}</span>
                            </li>
                            <li>
                                <strong>Link: </strong>{" "}
                                <span>{jobDetails?.JobLink ?? "N/A"}</span>
                            </li>
                        </ul>
                    </div>
                    <CenterModal
                        show={showMyModal}
                        onClose={() => {
                            setShowMyModal(false)
                        }}
                        heading={message.heading}
                        description={message.description}
                        size={"md"}
                    />
                </ConditionalLoader>
            </SessionTimoutPopup>
        </CustomPopup>
    )
}

export default ViewJobPopup