import React from 'react'
import ImageSubstitute from "../ImageSubstitute";

const MemberUserCard = ({
    imgUrl,
    name,
    gender,
    city,
    className,
    viewMore
}) => {
    return (
        <div className={`col-md-4 col-lg-3 col-sm-6 mb-3 ${className}`}>
            <div className="card text-center border-none team-item-1">
                <div className="lab-inner">
                    <div className="lab-thumb" style={{
                        minHeight: '180px'
                    }}>
                        {imgUrl && imgUrl !== `${process.env.REACT_APP_API_URL}/` ? <img
                            src={imgUrl}
                            className="card-img-top"
                            alt="Profile Image"
                        /> :
                            <div style={{
                                height: '150px',
                                width: '150px',
                                marginInline: 'auto',
                                borderRadius: '100%',
                                overflow: 'hidden',
                                border: '5px solid #efefef'
                            }}>
                                <ImageSubstitute name={name} />
                            </div>
                        }
                    </div>
                    <div className="lab-content">
                        <p href="#">
                            <h6 className="card-title mb-0">{name} </h6>
                        </p>
                        <ul className="list-unstyled">
                            <li>
                                <strong>Gender:</strong> <span>{gender}</span>
                            </li>
                            <li>
                                <strong>City:</strong> <span>{city}</span>
                            </li>
                        </ul>
                        <div className="social-share">
                            <button
                                className='btn btn-hover-green'
                                style={{
                                    outline: 'none !important',
                                    boxShadow: 'none'
                                }}
                                onClick={viewMore}
                            >
                                View Profile
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberUserCard