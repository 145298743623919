import React, { useContext, useEffect, useState } from "react";
import { MemberDetails } from "../../Contexts/MemberDetailContext";
import { Spinner } from "react-bootstrap";
import EducationRow from "./EducationRow";
import { getApiResponse, postData } from "../../Utils/AxiosRequest";
import {
  API_PATHS,
  errorMessages
} from "../../Utils/Constants/constants";
import { useFieldArray, useForm } from "react-hook-form";
import ConditionalLoader from "../ConditionalLoader";
import Modal from "../Modal";
import { useNavigate, useOutletContext } from "react-router-dom";
import SessionTimoutPopup from "../SessionTimoutPopup";

const EducationUpdate = () => {
  const { memberDetails, education, isLoading, setIsUpdated } =
    useContext(MemberDetails);
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [degrees, setDegrees] = useState([]);
  const [graders, setGraders] = useState([]);
  const [isLoadingForm, setIsLoadingForm] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isEducationUpdated, setIsEducationUpdated] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const [sessionTimeout, setSessionTimeout] = useState(false);
  const { updateToastMessage } = useOutletContext();

  const navigate = useNavigate();

  const educationList = education.map((item) => ({
    DegreeId: item.DegreeId,
    PassingYear: item.PassingYear,
    StartDate: item.StartDate.slice(0, 10),
    EndDate: item.EndDate.slice(0, 10),
    InstitutionName: item.InstitutionName,
    FieldOfStudy: item.FieldOfStudy,
    DegreeGradeId: item.DegreeGradeId,
    DegreePercentage: item.DegreePercentage,
    DegreeDescription: item.DegreeDescription,
  }));

  const memberEducationTemplate = {
    MemberId: "",
    DegreeId: "0",
    PassingYear: "",
    StartDate: "",
    EndDate: "",
    InstitutionName: "",
    FieldOfStudy: "",
    DegreeGradeId: "0",
    DegreePercentage: "",
    DegreeDescription: "",
    CreatedBy: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      forms: education.length ? educationList : [memberEducationTemplate],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "forms",
  });

  const addEducation = (e) => {
    e.preventDefault();
    append(memberEducationTemplate);
  };
  const closePopup = () => {
    setShowModal(false);
    setMessage({
      heading: "",
      description: "",
    });
    if (isEducationUpdated) {
      setIsUpdated(true);
    }
  };

  const updateEducation = async (data) => {
    setIsSubmiting(true);
    const requestBody = data.forms.map((item) => ({
      ...item,
      MemberId: memberDetails.MemberId,
      CreatedBy: memberDetails.MemberId,
      ModifiedBy: memberDetails.MemberId,
      IsActive: education.length ? item.IsActive : true
    }));
    try {
      const response = await postData(API_PATHS.updateEducation, requestBody, {
        bearer: true,
      });
      switch (response.status) {
        case 200:
          if (response.data.IsSuccess) {
            updateToastMessage("Education Updated successfully.", "success");
            setIsEducationUpdated(true);
          } else {
            updateToastMessage("Education Updated successfully.", "danger");
          }
          break;
        case 400:
          updateToastMessage("Validation Error.", "danger");
          break;
        case 401:
          setSessionTimeout(true);
          break;
        default:
          updateToastMessage(errorMessages.error, "danger");
      }
    } catch (error) {
      console.error("Error:", error);
      updateToastMessage(errorMessages.error, "danger");
    }
    setIsSubmiting(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingForm(true);
      try {
        const degreeResponse = await getApiResponse(API_PATHS.getDegrees);
        if (degreeResponse.status === 200) {
          setDegrees(degreeResponse.data);
        }
        const gradeResponse = await getApiResponse(API_PATHS.getGrades);
        if (gradeResponse.status === 200) {
          setGraders(gradeResponse.data);
        }
      } catch (error) {
        console.error("Error", error);
      }
      setIsLoadingForm(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setValue("forms", educationList);
  }, [education]);

  return (
    <ConditionalLoader isLoading={isLoadingForm || isLoading}>
      <SessionTimoutPopup isSessionTimout={sessionTimeout}>
        <form onSubmit={handleSubmit(updateEducation)}>
          <div className="tab-pane">
            <h4 className="sub-title mb-4">Education Detail</h4>
            <div className="" id="add-education-detail">
              {fields.map((item, index) => (
                <EducationRow
                  key={index}
                  index={index}
                  register={register}
                  isSubmitting={isSubmitting}
                  grades={graders}
                  degrees={degrees}
                  errors={errors?.forms?.[index]}
                  isDirty={isDirty}
                  item={item}
                  lastItem={index === fields.length - 1}
                  setValue={setValue}
                  getValues={getValues}
                  remove={remove}
                />
              ))}
            </div>
            <div>
              <button
                className="btn green-btn mt-4"
                id="add-education-btn"
                onClick={addEducation}
              >
                <i className="fas fa-plus"></i> Add Row
              </button>
            </div>
            <ul className="w-full list-inline text-right d-flex justify-content-end">
              <li>
                {isSubmitting ? (
                  <button className="btn green-btn px-5 cursor-na" disabled>
                    <Spinner size="sm" />
                  </button>
                ) : (
                  <input
                    type="submit"
                    className="btn green-btn"
                    value="Save"
                    disabled={
                      errors?.forms?.length > 0 || !isDirty || isSubmitting
                    }
                  />
                )}
              </li>
            </ul>
          </div>
        </form>
        <Modal
          showModal={showModal}
          closeModal={closePopup}
          heading={message.heading}
          summary={message.description}
        />
      </SessionTimoutPopup>
    </ConditionalLoader>
  );
};

export default EducationUpdate;
