import React, { useRef, useState } from "react";

const ContactUsForm = () => {
  const form = useRef(null);
  const submitForm = (event) => {
    event.preventDefault();
    const inputs = event.target.elements;
  };

  return (
    <form ref={form} onSubmit={submitForm}>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <input
              name="fname"
              type="text"
              className="form-control"
              placeholder="First Name"
              required
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <input
              name="lname"
              type="text"
              className="form-control"
              placeholder="Last Name"
              required
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <input
              name="city"
              type="text"
              className="form-control"
              placeholder="City"
              required
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <select name="state" className="custom-select" defaultValue={""}>
              <option value="">State</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Delhi">Delhi</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Goa">Goa</option>
            </select>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <input
              name="phoneNo"
              type="text"
              min={10}
              max={10}
              className="form-control"
              placeholder="Phone No"
              required
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <input
              name="email"
              type="email"
              className="form-control"
              placeholder="Email"
              required
            />
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <textarea
              name="message"
              className="form-control"
              placeholder="Message"
              required
              rows="5"
            ></textarea>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <input
              name="submit"
              type="submit"
              className="btn green-btn w-100"
              value="Send Our Message"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ContactUsForm;
