import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { checkIsSelected, checkNameSpecialCharacters, checkOnlyCharacters, checkOnlyNumber, phoneValidate, pincodeValidate } from "../../Utils/validation/validators";
import ConditionalLoader from "../../Components/ConditionalLoader";
import { getApiResponse, postData } from "../../Utils/AxiosRequest";
import { API_PATHS, errorMessages, URL_PATH } from "../../Utils/Constants/constants";
import { MemberDetails } from "../../Contexts/MemberDetailContext";
import { matrimonialKeys } from "../../Utils/Constants/payload"
import { Spinner } from "react-bootstrap";
import CenterModal from "../../Components/CenterModal";
import SessionTimoutPopup from "../../Components/SessionTimoutPopup";


const MatrimonialCreateProfile = ({ edit }) => {
  const { memberDetails, matProfileCount, setMatProfileCount } =
    useContext(MemberDetails);
  const { updateToastMessage } = useOutletContext();

  const { MemberId } = memberDetails;
  const [apiData, setApiData] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [pageError, setError] = useState("");
  const [file, setFile] = useState('');
  const [nationalities, setNationalities] = useState([]);
  const [genders, setGenders] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [religions, setReligions] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [skinColor, setSkinColor] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [motherTounge, setMotherTounge] = useState([]);
  const [profileCatagory, setProfileCatagory] = useState([]);
  const [annualIncome, setAnnualIncome] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sessionTImeout, setSessionTimeout] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const { id } = useParams();
  const closePopup = () => {
    setMessage({
      heading: "",
      description: "",
    });
    setShowModal(false);
  };
  const navigate = useNavigate();


  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm();

  const formatReqBody = (data) => {
    const formData = { ...data };
    const reqBody = {};
    Object.keys(formData).forEach((key) => {
      let value = formData[key];
      if (key === "UploadedFile") {
        const fileArray = Array.from(value);
        reqBody[key] = fileArray[0];
      } else if (
        key === "GenderId" ||
        key === "CurrentDesignationId" ||
        key === "NationalityId" ||
        key === "CountryId" ||
        key === "StateId" ||
        key === "DistrictId" ||
        key === "CategoryId" ||
        key === "MaritalStatusId" ||
        key === "ReligionId" ||
        key === "BodyTypeId" ||
        key === "SkinColorId" ||
        key === "OccupationId" ||
        key === "MotherTongueId" ||
        key === "ProfileCategoryId" ||
        key === "AnnualIncomeId" ||
        key === "FatherOccupationId" ||
        key === "MotherOccupationId"
      ) {
        if (value === "0") {
          reqBody[key] = "";
        } else {
          reqBody[key] = value;
        }
      } else if (key === "IsPhysicallyDisabled") {
        reqBody[key] = value === "1" ? true : false;
      } else if (key === "DOB") {
        const date = new Date(value).toLocaleDateString("en", "dd-mm-yyyy");
        reqBody[key] = date;
      } else if (typeof value === "string") {
        reqBody[key] = value.trim();
      } else {
        reqBody[key] = value;
      }
    });
    reqBody.MemberId = MemberId;
    reqBody.CreatedBy = MemberId;
    reqBody.ModifiedBy = MemberId;
    reqBody.IsActive = edit ? reqBody.IsActive : true;
    return reqBody;
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const reqBody = formatReqBody(data);


    try {
      const response = await postData(API_PATHS.createMatrimonialProfile, reqBody, {
        formData: true,
        bearer: true
      });

      switch (response.status) {
        case 200:
          if (response.data.IsSuccess) {
            window.scrollTo(0, 0);
            updateToastMessage(edit ? "Profile Updated Successfully" : "Profile Created Successfully", "success");
            setMatProfileCount(matProfileCount + 1);
            navigate(URL_PATH.matrimonialprofiles);
          } else if (response.data.Message === "Member exceed matrimonial profile creation. Only 5 profiles are allowed.") {
            updateToastMessage(edit ? errorMessages.error : "Profile Creation Limit Exceeded", "danger");
          } else {
            updateToastMessage(errorMessages.error, "danger");
          }
          break;
        case 400:
          updateToastMessage("Validation Error", "danger");
          break;
        default:
          updateToastMessage(errorMessages.error, "danger");

      }
    } catch (error) {
      console.error(error);
      updateToastMessage(errorMessages.error, "danger");
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        const [
          response,
          genderResponse,
          designationResponse,
          countriesResponse,
          statesResponse,
          districtResponse,
          categoriesResponse,
          maritalStatusResponse,
          bodyTypeResponse,
          relegionResponse,
          skinColorResponse,
          motherToungeResponse,
          annualIncomeResponse,
          occupationResponse,
          profileCatagoryResponse,
        ] = await Promise.all([
          getApiResponse(API_PATHS.getNationalities),
          getApiResponse(API_PATHS.getGenders),
          getApiResponse(API_PATHS.getDesignations),
          getApiResponse(API_PATHS.getCountries),
          getApiResponse(API_PATHS.getStates),
          getApiResponse(API_PATHS.getDisticts),
          getApiResponse(API_PATHS.getCategories),
          getApiResponse(API_PATHS.getMaritalStatus),
          getApiResponse(API_PATHS.getBodyType),
          getApiResponse(API_PATHS.getRelegios),
          getApiResponse(API_PATHS.getComplexions),
          getApiResponse(API_PATHS.getMotherTounges),
          getApiResponse(API_PATHS.getAnnualIncomes),
          getApiResponse(API_PATHS.getOccupations),
          getApiResponse(API_PATHS.getProfileCategories)
        ])

        if (response.status === 200) {
          setNationalities(response.data);
        }

        if (genderResponse.status === 200) {
          setGenders(genderResponse.data ?? []);
        }

        if (designationResponse.status === 200) {
          setDesignations(designationResponse.data ?? []);
        }

        if (countriesResponse.status === 200) {
          setCountries(countriesResponse.data ?? []);
        }

        if (statesResponse.status === 200) {
          setStates(statesResponse.data ?? []);
        }

        if (districtResponse.status === 200) {
          setDistricts(districtResponse.data ?? []);
        }

        if (categoriesResponse.status === 200) {
          setCategories(categoriesResponse.data ?? []);
        }

        if (maritalStatusResponse.status === 200) {
          setMaritalStatus(maritalStatusResponse.data ?? []);
        }

        if (bodyTypeResponse.status === 200) {
          setBodyTypes(bodyTypeResponse.data ?? []);
        }

        if (relegionResponse.status === 200) {
          setReligions(relegionResponse.data ?? []);
        }

        if (skinColorResponse.status === 200) {
          setSkinColor(skinColorResponse.data ?? []);
        }

        if (motherToungeResponse.status === 200) {
          setMotherTounge(motherToungeResponse.data ?? []);
        }

        if (annualIncomeResponse.status === 200) {
          setAnnualIncome(annualIncomeResponse.data ?? []);
        }

        if (occupationResponse.status === 200) {
          setOccupation(occupationResponse.data ?? []);
        }

        if (profileCatagoryResponse.status === 200) {
          setProfileCatagory(profileCatagoryResponse.data ?? []);
        }
      } catch (error) {
        setError(errorMessages.error);
      }
      setIsLoadingData(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const mapDataFromAPI = async (apiData) => {
      Object.keys(apiData).forEach((key) => {
        if (matrimonialKeys.includes(key)) {
          if (key === "DOB") {
            setValue(key, apiData[key]?.slice(0, 10));
          } else if (apiData[key] === "00000000-0000-0000-0000-000000000000") {
            setValue(key, "0");
          } else if (key === "IsPhysicallyDisabled") {
            setValue(key, apiData[key] ? "1" : "2");
          } else {
            setValue(key, apiData[key] ?? "");
          }
        }
      });
    }
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        if (edit) {
          const profileResponse = await getApiResponse(
            `${API_PATHS.getMatrimonialProfile}?profileid=${id}`, true
          )
          if (profileResponse.status === 200) {
            setApiData(profileResponse.data);
            await mapDataFromAPI(profileResponse.data)
          } else if (profileResponse.status === 401) {
            setSessionTimeout(true);
          } else {
            setShowModal(true);
            setMessage({
              heading: errorMessages.error,
              description: errorMessages.description
            })
          }
        }
      } catch (error) {
        setError(errorMessages.error);
      }
      setIsLoadingData(false);
    }
    fetchData();
  }, [edit, id, setValue])

  return (
    <SessionTimoutPopup isSessionTimout={sessionTImeout}>
      <div className="update-profile-body">
        <h2 className="main-title mt-3 mb-3">{edit ? 'Edit' : 'Create'} Matrimonial Profile</h2>
        <section className="update-profile-container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              <div className="wizard memnership-form">

                <ConditionalLoader isLoading={isLoadingData} error={pageError}>
                  <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="tab-content" id="main_form">
                      <div className="tab-pane active" role="tabpanel" id="step1">
                        {/* <h4 className="sub-title mb-4">Personal Detail</h4> */}
                        <div className="row">
                          <div className="col-sm-12 row pb-2">
                            <div className="col-sm-4 col-md-3">
                              {apiData.DisplayURL || file ? <div className="profile-img">
                                <img src={file ? file : apiData?.DisplayURL} alt="" />
                              </div> :
                                <></>}
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <div className="custom-file" style={{
                                zIndex: 0
                              }}>
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  accept="image/*"
                                  id="customFile"
                                  {...register("UploadedFile", {
                                    onChange: (e) => { setFile(URL.createObjectURL(e.target.files[0])) }
                                  })}
                                  disabled={isSubmitting}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="customFile"
                                >
                                  Upload Your Image
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${errors.FirstName && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.FirstName && isDirty
                                    ? errors.FirstName.message
                                    : "First Name"
                                }
                                autoComplete="off"
                                {...register("FirstName", {
                                  required: "First Name is required",
                                  validate: (v) =>
                                    checkNameSpecialCharacters(v, "First Name"),
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${errors.LastName && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.LastName && isDirty
                                    ? errors.LastName.message
                                    : "Last Name"
                                }
                                autoComplete="off"
                                {...register("LastName", {
                                  required: "Last Name is required",
                                  validate: (v) =>
                                    checkNameSpecialCharacters(v, "Last Name"),
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="email"
                                className={`form-control ${errors.Email && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.Email && isDirty
                                    ? errors.Email.message
                                    : "Email"
                                }
                                autoComplete="off"
                                {...register("Email", {
                                  required: "Email is required",
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${errors.FatherName && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.FatherName && isDirty
                                    ? errors.FatherName.message
                                    : "Father’s Name"
                                }
                                autoComplete="off"
                                {...register("FatherName", {
                                  required: "Father's Name is required",
                                  validate: (v) =>
                                    checkNameSpecialCharacters(v, "Father's Name"),
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${errors.DOB && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.DOB && isDirty ? errors.DOB.message : "DOB"
                                }
                                {...register("DOB", {
                                  max: {
                                    value: new Date().toLocaleDateString(),
                                    message: "Please select a valid DOB",
                                  },
                                  required: "Please add your Date of Birth",
                                })}
                                onFocus={(e) => {
                                  e.target.type = "date";
                                }}
                                onBlur={(e) => {
                                  e.target.type = "text";
                                }}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.CurrentDesignationId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.CurrentDesignationId && isDirty
                                    ? errors.CurrentDesignationId.message
                                    : "Designation"
                                }
                                defaultValue={"0"}
                                {...register("CurrentDesignationId", {
                                  required: "Please select Designation",
                                  validate: (v) => checkIsSelected(v, "Designation"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Designation
                                </option>
                                {designations.length > 0 &&
                                  designations.map((item) => (
                                    <option
                                      key={item.DesignationId}
                                      value={item.DesignationId}
                                    >
                                      {item.DesignationDesc.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.NationalityId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.NationalityId && isDirty
                                    ? errors.NationalityId.message
                                    : "Nationality"
                                }
                                defaultValue={"0"}
                                {...register("NationalityId", {
                                  validate: (v) => checkIsSelected(v, "Nationality"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Nationality
                                </option>
                                {nationalities.length > 0 &&
                                  nationalities.map((item) => (
                                    <option
                                      key={item.NationalityId}
                                      value={item.NationalityId}
                                    >
                                      {item.Country.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.GenderId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                defaultValue={"0"}
                                {...register("GenderId", {
                                  validate: (v) => checkIsSelected(v, "Gender"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Gender
                                </option>
                                {genders.length > 0 &&
                                  genders.map((item) => (
                                    <option key={item.GenderId} value={item.GenderId}>
                                      {item.GenderDesc.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${errors.AddressLine1 && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.AddressLine1 && isDirty
                                    ? errors.AddressLine1.message
                                    : "Permanent Address Line-1"
                                }
                                {...register("AddressLine1", {
                                  required: "Please enter Address",
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${errors.AddressLine2 && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.AddressLine2 && isDirty
                                    ? errors.AddressLine2.message
                                    : "Permanent Address Line-2"
                                }
                                {...register("AddressLine2")}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${errors.CityName && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.CityName && isDirty
                                    ? errors.CityName.message
                                    : "City"
                                }
                                {...register("CityName", {
                                  required: "Please enter your city",
                                  validate: (v) => checkOnlyCharacters(v, "City"),
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.CountryId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.CountryId && isDirty
                                    ? errors.CountryId.message
                                    : "Country"
                                }
                                defaultValue={"0"}
                                {...register("CountryId", {
                                  validate: (v) => checkIsSelected(v, "Country"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Country
                                </option>
                                {countries.length > 0 &&
                                  countries.map((item) => (
                                    <option
                                      key={item.CountryId}
                                      value={item.CountryId}
                                    >
                                      {item.Name.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.StateId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.StateId && isDirty
                                    ? errors.StateId.message
                                    : "Country"
                                }
                                defaultValue={"0"}
                                {...register("StateId", {
                                  validate: (v) => checkIsSelected(v, "State"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  State
                                </option>
                                {states.length > 0 &&
                                  states.map((item) => (
                                    <option key={item.StateId} value={item.StateId}>
                                      {item.Name.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.DistrictId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.DistrictId && isDirty
                                    ? errors.DistrictId.message
                                    : "Country"
                                }
                                defaultValue={"0"}
                                {...register("DistrictId", {
                                  validate: (v) => checkIsSelected(v, "District"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  District
                                </option>
                                {districts.length > 0 &&
                                  districts.map((item) => (
                                    <option key={item.CityId} value={item.CityId}>
                                      {item.Name.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${errors.Pincode && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.Pincode && isDirty
                                    ? errors.Pincode.message
                                    : "Pincode"
                                }
                                {...register("Pincode", {
                                  validate: pincodeValidate,
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${errors.PhoneNumber && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.PhoneNumber && isDirty
                                    ? errors.PhoneNumber.message
                                    : "Phone No."
                                }
                                {...register("PhoneNumber", {
                                  required: "Please enter your phone number",
                                  maxLength: {
                                    value: 10,
                                    message: "Please enter a valid mobile number",
                                  },
                                  minLength: {
                                    value: 10,
                                    message: "Please enter a valid mobile number",
                                  },
                                  validate: phoneValidate,
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.CategoryId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.CategoryId && isDirty
                                    ? errors.CategoryId.message
                                    : "Category"
                                }
                                defaultValue={"0"}
                                {...register("CategoryId", {
                                  required: "Please select Category",
                                  validate: (v) => checkIsSelected(v, "Category"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Category
                                </option>
                                {categories.length > 0 &&
                                  categories.map((item) => (
                                    <option
                                      key={item.CategoryId}
                                      value={item.CategoryId}
                                    >
                                      {item.CategoryDesc.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.MaritalStatusId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.MaritalStatusId && isDirty
                                    ? errors.MaritalStatusId.message
                                    : "Marital Status"
                                }
                                defaultValue={"0"}
                                {...register("MaritalStatusId", {
                                  required: "Please select Marital Status",
                                  validate: (v) => checkIsSelected(v, "Marital Status"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Marital Status
                                </option>
                                {maritalStatus.length > 0 &&
                                  maritalStatus.map((item) => (
                                    <option
                                      key={item.MaritalStatusId}
                                      value={item.MaritalStatusId}
                                    >
                                      {item.MaritalStatusDesc.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.ReligionId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.ReligionId && isDirty
                                    ? errors.ReligionId.message
                                    : "Religion"
                                }
                                defaultValue={"0"}
                                {...register("ReligionId", {
                                  required: "Please select Religion",
                                  validate: (v) => checkIsSelected(v, "Religion"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Religion
                                </option>
                                {religions.length > 0 &&
                                  religions.map((item) => (
                                    <option
                                      key={item.ReligionId}
                                      value={item.ReligionId}
                                    >
                                      {item.ReligionDesc.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${errors.SubCaste && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.SubCaste && isDirty
                                    ? errors.SubCaste.message
                                    : "Sub-Caste"
                                }
                                autoComplete="off"
                                {...register("SubCaste", {
                                  required: "Sub-Caste is required",
                                  validate: (v) =>
                                    checkOnlyCharacters(v, "Sub-Caste"),
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${errors.Height && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.Height && isDirty
                                    ? errors.Height.message
                                    : "Height in CM"
                                }
                                autoComplete="off"
                                {...register("Height", {
                                  required: "Height is required",
                                  min: {
                                    value: 50,
                                    message: "Please enter a valid height in cm",
                                  },
                                  max: {
                                    value: 250,
                                    message: "Please enter a valid height in cm",
                                  },
                                  validate: (v) => checkOnlyNumber(v, "Height")
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${errors.Weight && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.Weight && isDirty
                                    ? errors.Weight.message
                                    : "Weight in Kg"
                                }
                                autoComplete="off"
                                {...register("Weight", {
                                  required: "Weight is required",
                                  min: {
                                    value: 20,
                                    message: "Please enter a valid weight in Kg",
                                  },
                                  max: {
                                    value: 350,
                                    message: "Please enter a valid weight in Kg",
                                  },
                                  validate: (v) => checkOnlyNumber(v, "Weight")
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.BodyTypeId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.BodyTypeId && isDirty
                                    ? errors.BodyTypeId.message
                                    : "Body Type"
                                }
                                defaultValue={"0"}
                                {...register("BodyTypeId", {
                                  required: "Please select Body Type",
                                  validate: (v) => checkIsSelected(v, "Body Type"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Body Type
                                </option>
                                {bodyTypes.length > 0 &&
                                  bodyTypes.map((item) => (
                                    <option
                                      key={item.BodyTypeId}
                                      value={item.BodyTypeId}
                                    >
                                      {item.BodyTypeDesc.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.SkinColorId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.SkinColorId && isDirty
                                    ? errors.SkinColorId.message
                                    : "Complexion"
                                }
                                defaultValue={"0"}
                                {...register("SkinColorId", {
                                  required: "Please select Complexion",
                                  validate: (v) => checkIsSelected(v, "Complexion"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Complexion
                                </option>
                                {skinColor.length > 0 &&
                                  skinColor.map((item) => (
                                    <option
                                      key={item.SkinColorId}
                                      value={item.SkinColorId}
                                    >
                                      {item.SkinColorDesc.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.OccupationId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.OccupationId && isDirty
                                    ? errors.OccupationId.message
                                    : "OccupationId"
                                }
                                defaultValue={"0"}
                                {...register("OccupationId", {
                                  required: "Please select Occupation",
                                  validate: (v) => checkIsSelected(v, "Occupation"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Occupation
                                </option>
                                {occupation.length > 0 &&
                                  occupation.map((item) => (
                                    <option
                                      key={item.OccupationId}
                                      value={item.OccupationId}
                                    >
                                      {item.OccupationDesc.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.Annual_IncomeId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.Annual_IncomeId && isDirty
                                    ? errors.Annual_IncomeId.message
                                    : "Annual Income"
                                }
                                defaultValue={"0"}
                                {...register("Annual_IncomeId", {
                                  required: "Please select Annual Income",
                                  validate: (v) => checkIsSelected(v, "Annual Income"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Annual Income
                                </option>
                                {annualIncome.length > 0 &&
                                  annualIncome.map((item) => (
                                    <option
                                      key={item.Annual_IncomeId}
                                      value={item.Annual_IncomeId}
                                    >
                                      {item.Annual_IncomeDesc.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.FatherOccupationId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.FatherOccupationId && isDirty
                                    ? errors.FatherOccupationId.message
                                    : "Father Occupation"
                                }
                                defaultValue={"0"}
                                {...register("FatherOccupationId", {
                                  required: "Please select Father Occupation",
                                  validate: (v) => checkIsSelected(v, "Father Occupation"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Father Occupation
                                </option>
                                {occupation.length > 0 &&
                                  occupation.map((item) => (
                                    <option
                                      key={item.OccupationId}
                                      value={item.OccupationId}
                                    >
                                      {item.OccupationDesc.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.MotherOccupationId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.MotherOccupationId && isDirty
                                    ? errors.MotherOccupationId.message
                                    : "Mother Occupation"
                                }
                                defaultValue={"0"}
                                {...register("MotherOccupationId", {
                                  required: "Please select Mother Occupation",
                                  validate: (v) => checkIsSelected(v, "Mother Occupation"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Mother Occupation
                                </option>
                                {occupation.length > 0 &&
                                  occupation.map((item) => (
                                    <option
                                      key={item.OccupationId}
                                      value={item.OccupationId}
                                    >
                                      {item.OccupationDesc.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.MotherToungeId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.MotherToungeId && isDirty
                                    ? errors.MotherToungeId.message
                                    : "Mother Tounge"
                                }
                                defaultValue={"0"}
                                {...register("MotherToungeId", {
                                  required: "Please select Mother Tounge",
                                  validate: (v) => checkIsSelected(v, "Mother Tounge"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Mother Tounge
                                </option>
                                {motherTounge.length > 0 &&
                                  motherTounge.map((item) => (
                                    <option
                                      key={item.MotherTongueId}
                                      value={item.MotherTongueId}
                                    >
                                      {item.MotherTongueDesc.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.ProfileCatId && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.ProfileCatId && isDirty
                                    ? errors.ProfileCatId.message
                                    : "Relation"
                                }
                                defaultValue={"0"}
                                {...register("ProfileCatId", {
                                  required: "Please select Relation",
                                  validate: (v) => checkIsSelected(v, "Relation"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Relation
                                </option>
                                {profileCatagory.length > 0 &&
                                  profileCatagory.map((item) => (
                                    <option
                                      key={item.ProfileCatId}
                                      value={item.ProfileCatId}
                                    >
                                      {item.ProfileCatName?.toUpperCase()}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="number"
                                className={`form-control ${errors.NumberOfBrother && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.NumberOfBrother && isDirty
                                    ? errors.NumberOfBrother.message
                                    : "Number Of Brother"
                                }
                                autoComplete="off"
                                {...register("NumberOfBrother", {
                                  defaultValue: 0,
                                  min: {
                                    value: 0,
                                    message: "Enter valid Number Of Brother"
                                  },
                                  max: {
                                    value: 10,
                                    message: "Enter valid Number Of Brother"
                                  },
                                  required: "Number Of Brother is required",
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <input
                                type="number"
                                className={`form-control ${errors.NumberOfSister && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.NumberOfSister && isDirty
                                    ? errors.NumberOfSister.message
                                    : "Number Of Sister"
                                }
                                autoComplete="off"
                                {...register("NumberOfSister", {
                                  defaultValue: "0",
                                  min: {
                                    value: 0,
                                    message: "Enter valid Number Of Sister"
                                  },
                                  max: {
                                    value: 10,
                                    message: "Enter valid Number Of Sister"
                                  },
                                  required: "Number Of Sister is required",
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                              <select
                                className={`custom-select ${errors.IsPhysicallyDisabled && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.IsPhysicallyDisabled && isDirty
                                    ? errors.IsPhysicallyDisabled.message
                                    : "Physically Disabled"
                                }
                                defaultValue={"0"}
                                {...register("IsPhysicallyDisabled", {
                                  required: "Please select Physically Disabled",
                                  validate: (v) => checkIsSelected(v, "Physically Disabled"),
                                })}
                                disabled={isSubmitting}
                              >
                                <option key={0} value={"0"}>
                                  Physically Disabled
                                </option>
                                <option key={1} value={"1"}>
                                  Yes
                                </option>
                                <option key={2} value={"2"}>
                                  No
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-8">
                            <div className="form-group">
                              <textarea
                                type="text"
                                className={`form-control ${errors.AboutMe && isDirty
                                  ? "red-placeholder border border-danger"
                                  : ""
                                  }`}
                                placeholder={
                                  errors.AboutMe && isDirty
                                    ? errors.AboutMe.message
                                    : "About Me"
                                }
                                autoComplete="off"
                                {...register("AboutMe", {
                                  required: "About Me is required",
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 row pb-4">
                          {(isDirty &&
                            errors) ?
                            Object.values(errors).map((error, index) => (
                              <div className="col-sm-6" key={index}>
                                <p className="text-danger text-left p-0 m-0">{`* ${error.message}`}</p>
                              </div>
                            )) : <></>}
                        </div>
                        <div className="col-md-12">
                          <div className="form-group text-right">
                            {isSubmitting ? (
                              <button
                                className="btn green-btn px-5 cursor-na"
                                disabled
                              >
                                <Spinner size="sm" />
                              </button>
                            ) : (
                              <input
                                type="submit"
                                className="btn green-btn"
                                value="Submit"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </ConditionalLoader>
              </div>
            </div>
          </div>
          {/* <Modal
          showModal={showModal}
          closeModal={closePopup}
          heading={message.heading}
          summary={message.description}
          bgBlurr={true}
        /> */}
          <CenterModal
            show={showModal}
            onClose={closePopup}
            heading={message.heading}
            description={message.description}
            buttonText={"Done"}
          />
        </section>
      </div>
    </SessionTimoutPopup>
  );
};

export default MatrimonialCreateProfile;
