import React, { useEffect, useState } from "react";
import Header from "../Components/Header.jsx";
import Footer from "../Components/Footer.jsx";
import "../Styles/all.min.css";
import "../Styles/bootstrap.min.css";
import "../Styles/slick.css";
import "../Styles/slick-theme.css";
import "../Styles/animate.min.css";
import "../Styles/aos.css";
import "../Styles/owl.carousel.min.css";
import "../Styles/style.css";
import "../Styles/responsive.css";
import { getApiResponse } from "../Utils/AxiosRequest.js";
import { ApiDataContext } from "../Contexts/ApiData.js";
import { useLocation, useNavigate } from "react-router-dom";
import { getApiUrl } from "../Utils/format.js";
import { API_PATHS } from "../Utils/Constants/constants.js";
import HeaderFooterErrorBar from "../Components/HeaderFooterErrorBar.jsx";
import ConditionalLoader from "../Components/ConditionalLoader.jsx";

const DefaultLayout = ({ children }) => {
  const [apiData, setApiData] = useState({});
  const [headerFooterData, setHeaderFooterData] = useState({});
  const [error, setError] = useState("");
  const [headerError, setHeaderError] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isHeaderLoading, setIsHeaderLoading] = useState(true);
  const { pathname } = useLocation();
  const isOnline = navigator.onLine;
  const navigate = useNavigate();

  const fetchData = async () => {
    if (!isOnline) {
      setError("No internet connection");
      return
    }
    setError("");
    let apiPath = getApiUrl(pathname);
    if (!apiPath && apiData?.CMSPage) {
      return;
    }
    if (apiPath == API_PATHS.eventbyid || apiPath == API_PATHS.newsbyid) {
      apiPath = API_PATHS.home;
    }
    if (!apiPath) {
      setIsLoading(false);
      return;
    }
    try {
      setIsLoading(true);
      const response = await getApiResponse(apiPath);
      if (response.status == 200) {
        setApiData(response.data);
      } else {
        setError("Something went wrong");
      }
    } catch (err) {
      console.error(err);
      setError("Something went wrong");
    }
    setIsLoading(false);
  };

  const fetchHeader = async () => {
    if (!isOnline) {
      setError("No internet connection");
      return
    }
    setHeaderError("");
    setIsHeaderLoading(true);
    try {
      const response = await getApiResponse(API_PATHS.header);
      if (response.status === 200) {
        setHeaderFooterData(response.data ?? {});
      } else {
        setHeaderError("Something went wrong");
      }
    } catch (err) {
      console.error(err);
      setHeaderError("Something went wrong");
    }
    setIsHeaderLoading(false);
  };

  useEffect(() => {
    fetchHeader();
    let intId;
    if (!isOnline) {
      setError("No internet connection");
      intId = setInterval(() => {
        if (navigator.isOnLine) {
          navigate(0);
          clearInterval(intId);
        }
      })
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [pathname]);

  return (
    <ApiDataContext.Provider
      value={{
        ...apiData,
        isLoading,
        layout: headerFooterData,
        isHeaderLoading,
        userName,
        setUserName
      }}
    >
      <Header />
      <HeaderFooterErrorBar error={headerError} isOnLine={isOnline} fetchHeader={fetchHeader} />
      <ConditionalLoader
        isLoading={isLoading}
        error={error}
        refresh={fetchData}
      >
        <div style={{ minHeight: "500px" }}>{children}</div>
      </ConditionalLoader>
      <HeaderFooterErrorBar error={headerError} fetchHeader={fetchHeader} />
      <Footer />
    </ApiDataContext.Provider>
  );
};

export default DefaultLayout;
