import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Banner from "../../Components/Banner";
import { getApiUrl } from "../../Utils/format";
import { getApiResponse } from "../../Utils/AxiosRequest";
import { Spinner } from "react-bootstrap";
import ButtonSlider from "../../Components/Carousel/ButtonSlider";
import NewsCard from "../../Components/Cards/NewsCard";
import { useNavigateToNews } from "../../Hooks/Navigation";

const NewsDetails = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const navigateToNews = useNavigateToNews();

  const [apiData, setapiData] = useState({});
  const [isLoading, setisLoading] = useState(true);

  const pageBanner = apiData?.PageBanner;
  const newsList = apiData?.LatestNews;
  const moment = apiData?.Display_MomentImages;
  const news = apiData?.News;
  const breadCrumb = [
    {
      url: "/",
      title: "Home",
    },
    {
      url: "/nwes",
      title: "News",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setisLoading(true);
        const apiPath = getApiUrl(pathname) + `/${id}`;
        const response = await getApiResponse(apiPath);
        if (response.status == 200) {
          setapiData(response.data);
        }
        setisLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "500px" }}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <React.Fragment>
      {pageBanner && pageBanner.length ? (
        <Banner
          breadCrumb={breadCrumb}
          heading={pageBanner[0].PageBannerTitle}
          imgUrl={pageBanner[0].DisplayURL}
          alt={"News Detail"}
        />
      ) : (
        <></>
      )}
      <section className="event-detail">
        <div className="container">
          <div>
            <a
              href="#"
              title="Event"
              className="main-link"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              <i className="fas fa-arrow-left"></i> Back to News
            </a>
          </div>
          <h2 className="main-title">{news?.NewsTitle}</h2>
          <div className="event-place-date">
            <div className="event-date">
              <span>{news.ScheduleDate}</span>
            </div>
            {/* <div className="event-place">
              Location : <span>{news?.}</span>
            </div> */}
          </div>
          <div className="event-img-1">
            <img src={news?.DisplayURL} alt="" />
          </div>
          <div
            className="html_render"
            dangerouslySetInnerHTML={{ __html: news.NewsContent }}
          ></div>
        </div>
      </section>
      {newsList && newsList.length ? (
        <section className="latest-news">
          <div className="container">
            <h2 className="main-title mb-3">Related News</h2>
            <div className="latest-news-row">
              <ButtonSlider>
                <>
                  {newsList.map((news, index) => (
                    <NewsCard
                      key={news?.NewsId ?? index}
                      imgUrl={news?.DisplayURL}
                      date={news?.ScheduleDate}
                      heading={news?.NewsTitle}
                      summary={news?.NewsContent}
                      readmore={() => navigateToNews(news?.NewsId)}
                    />
                  ))}
                </>
              </ButtonSlider>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default NewsDetails;
